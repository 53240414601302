import { Autocomplete, ModalAction as Modal } from '@get-e/react-components';
import { Box, Grid } from '@mui/material';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { RichTextEditor } from '../../../components/RichTextEditor';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import getHelperText from '../../../helpers/validation/getHelperText';
import InputError from '../../../helpers/validation/InputError';
import isFilledString from '../../../helpers/validation/validators/isFilledString';
import { createPaymentMethod, updatePaymentMethod } from '../../../services/paymentMethods';
import { Entity } from '../../invoices/api/types';
import { PaymentMethod } from '../api/types';
import { useCustomers } from '../api/useCustomers';
import { useHotels } from '../api/useHotels';

interface FormErrors {
    account: InputError | null;
    procedure: InputError | null;
}

interface AddEditPaymentMethodProps {
    isModalOpen: boolean;
    onClose: () => void;
    hotelId?: number;
    customerId?: number;
    onSuccess?: () => void;
    selectedPaymentMethod?: PaymentMethod | null;
    isPageEditHotelAccount?: boolean;
}

const AddEditPaymentMethodModal = ({
    isModalOpen,
    onClose,
    hotelId,
    customerId,
    onSuccess,
    selectedPaymentMethod,
    isPageEditHotelAccount,
}: AddEditPaymentMethodProps) => {
    const { t } = useTranslation();
    const { showNotification } = useNotificationContext();

    const [searchTerm, setSearchTerm] = useState('');
    const [customer, setCustomer] = useState<Entity | null>(null);
    const [hotel, setHotel] = useState<Entity | null>(null);
    const [procedure, setProcedure] = useState('');

    const [formErrors, setFormErrors] = useState<FormErrors>({
        account: null,
        procedure: null,
    });

    const { accounts: customers, isLoading: isLoadingCustomers } = useCustomers(searchTerm);
    const { hotels, isLoading: isLoadingHotels } = useHotels(searchTerm);

    useEffect(() => {
        if (isPageEditHotelAccount) {
            setCustomer(selectedPaymentMethod?.customer || null);
        } else {
            setHotel(selectedPaymentMethod?.hotel || null);
        }

        setProcedure(selectedPaymentMethod?.procedure || '');
    }, [selectedPaymentMethod]);

    const { mutate: updateMutation, isLoading: isLoadingEdit } = useMutation(updatePaymentMethod, {
        onSuccess: () => {
            showNotification('Payment method successfully edited.', Severity.Info);
            onClose();
            onSuccess?.();
        },
        onError: (error: AxiosError<{ message: string }>) => {
            showNotification(error?.response?.data?.message || t('errors.retry'), Severity.Error);
        },
    });

    const { mutate: createMutation, isLoading: isLoadingCreate } = useMutation(createPaymentMethod, {
        onSuccess: () => {
            showNotification('Payment method successfully created.', Severity.Info);
            onClose();
            onSuccess?.();
        },
        onError: (error: AxiosError<{ message: string }>) => {
            showNotification(error?.response?.data?.message || t('errors.retry'), Severity.Error);
        },
    });

    const validateFields = (): boolean => {
        const validatedAccount = isFilledString(
            (isPageEditHotelAccount ? customer?.name : hotel?.name) || '',
            InputError.Required
        );

        const validatedProcedure = isFilledString(procedure.trim() || '', InputError.Required);

        const fieldErrors: FormErrors = {
            account: validatedAccount.isValid ? null : validatedAccount.error,
            procedure: validatedProcedure.isValid ? null : validatedProcedure.error,
        };

        setFormErrors(fieldErrors);

        return Object.values(fieldErrors).every(error => error === null);
    };

    const handleSubmit = () => {
        if (!validateFields()) {
            return;
        }

        const payload = {
            hotelId: hotelId || hotel?.id,
            customerId: customerId || customer?.id,
            procedure,
            paymentMethod: 'DIRECT_BILLING',
            ...(selectedPaymentMethod?.id && { id: selectedPaymentMethod?.id?.toString() }),
        };

        if (selectedPaymentMethod) {
            updateMutation(payload);
        } else {
            createMutation(payload);
        }
    };

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            title={selectedPaymentMethod ? 'Edit payment method' : 'Add payment method'}
            confirmButtonLabel={'Save'}
            cancelButtonLabel={'Back'}
            maxWidth="md"
            fullWidth
            isDisabled={isLoadingCreate || isLoadingEdit}
        >
            {!isPageEditHotelAccount ? (
                <Autocomplete
                    label="Hotel"
                    required
                    options={(hotels as Entity[]) || []}
                    getOptionLabel={(option: Entity) => option.name}
                    renderOption={(prop, option: Entity) => {
                        return (
                            <Box component="li" {...prop}>
                                {option.name}
                            </Box>
                        );
                    }}
                    value={hotel}
                    onChange={(_, newValue: Entity | null) => {
                        setHotel(newValue);
                        setFormErrors({ ...formErrors, account: null });
                    }}
                    onInputChange={(_, newInputValue) => {
                        setSearchTerm(newInputValue);
                    }}
                    loading={isLoadingHotels}
                    sx={{ marginBottom: '2rem' }}
                    isError={formErrors.account !== null}
                    helperText={getHelperText(formErrors.account, t)}
                />
            ) : (
                <Autocomplete
                    label="Customer"
                    required
                    options={customers || []}
                    getOptionLabel={(option: Entity) => option.name}
                    renderOption={(prop, option: Entity) => {
                        return (
                            <Box component="li" {...prop}>
                                {option.name}
                            </Box>
                        );
                    }}
                    value={customer}
                    onChange={(_, newValue: Entity | null) => {
                        setCustomer(newValue);
                        setFormErrors({ ...formErrors, account: null });
                    }}
                    onInputChange={(_, newInputValue) => {
                        setSearchTerm(newInputValue);
                    }}
                    loading={isLoadingCustomers}
                    sx={{ marginBottom: '2rem' }}
                    isError={formErrors.account !== null}
                    helperText={getHelperText(formErrors.account, t)}
                />
            )}
            <Grid item xs={12}>
                <RichTextEditor
                    value={procedure}
                    onChange={newValue => {
                        setProcedure(newValue);

                        setFormErrors({ ...formErrors, procedure: null });
                    }}
                    title="Booking procedure"
                    required
                    errorText={getHelperText(formErrors.procedure, t)}
                />
            </Grid>
        </Modal>
    );
};

export default AddEditPaymentMethodModal;
