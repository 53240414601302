import { Box, Typography, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { COLORS } from '../constans/colors';

const useStyles = makeStyles({
    area: {
        '& .ql-toolbar': {
            background: COLORS.EXTRA_LIGHT_GRAY,
        },
        '& .ql-container': {
            height: '250px',
        },
    },
});

const toolbarModules = [
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link'],
    ['clean'],
];

interface RichTextEditorProps {
    value: string;
    onChange: (content: string) => void;
    title?: string;
    required?: boolean;
    errorText?: string;
}

export const RichTextEditor = ({ value, onChange, title, required, errorText }: RichTextEditorProps) => {
    const classes = useStyles();
    const showError = required && errorText;

    return (
        <Box>
            {title && (
                <Typography
                    sx={{
                        fontSize: '.75rem',
                        marginLeft: '.5rem',
                        paddingBottom: '.5rem',
                        color: showError ? COLORS.RED : 'inherit',
                    }}
                >
                    {title} {showError && '*'}
                </Typography>
            )}
            <ReactQuill
                theme="snow"
                value={value}
                onChange={onChange}
                placeholder="Write something..."
                modules={{
                    toolbar: toolbarModules,
                }}
                className={`${classes.area} ${showError ? 'ql-error' : ''}`}
            />
            {showError && <FormHelperText sx={{ color: COLORS.RED, marginLeft: '.5rem' }}>{errorText}</FormHelperText>}
        </Box>
    );
};
