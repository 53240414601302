import { useQuery } from 'react-query';

import { HOTEL } from '../../../constans/queryKeys';
import { viewHotel } from '../../../services/hotel';

export function useHotel(id: number) {
    return useQuery([HOTEL], () => viewHotel(id), {
        keepPreviousData: true,
        refetchOnWindowFocus: true,
        retry: 1,
        cacheTime: 500,
        enabled: Boolean(id),
    });
}
