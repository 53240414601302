import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, styled, Box } from '@mui/material';
import dayjs from 'dayjs';

import { COLORS } from '../../../constans/colors';
import { DATE_FORMATS } from '../../../helpers/dateFormats';
import { Change, LogEntry } from '../api/types';
import { StatusComponent } from '../uploadInvoice/components/StatusComponent';

const StyledTableCell = styled(TableCell)({
    borderRight: `1px solid ${COLORS.TABLE_BORDER}`,
    padding: '8px',
    fontSize: '.8rem',
    verticalAlign: 'baseline',
    '& > div': {
        display: 'flex',
        alignItems: 'center',
    },
});

const TableCellHeader = styled(TableCell)({
    fontSize: '.8rem',
    padding: '.3rem',
});

const fieldLabels: Record<string, string> = {
    currency: 'Currency',
    invoice_reference: 'Invoice Reference',
    total_amount: 'Total Amount',
    comments: 'Comments',
    invoice_date_local: 'Invoice Date',
    due_date_local: 'Due Date',
    bookingChannels: 'Booking channels',
    files: 'Files',
};

const renderValueChange = (field: string, value: Change) => {
    switch (field) {
        case 'due_date_local':
        case 'invoice_date_local':
            return (
                <>
                    <Box mr={'.4rem'}>{fieldLabels[field] || field}: </Box>
                    <>
                        {value.old && (
                            <>
                                <s style={{ color: COLORS.SLATE_GREY, marginRight: '.4rem' }}>
                                    {dayjs(value.old).format(DATE_FORMATS['DD MMM YYYY'])}{' '}
                                </s>{' '}
                                →
                            </>
                        )}
                    </>
                    {dayjs(value.new).format(DATE_FORMATS['DD MMM YYYY'])}
                </>
            );
        case 'files':
            return (
                <>
                    {value.new && (
                        <>
                            <Box mr=".3rem" color={COLORS.GREEN}>
                                New file:{' '}
                            </Box>
                            {value.new}
                        </>
                    )}
                    {value.deleted && (
                        <>
                            <Box mr=".3rem" color={COLORS.RED}>
                                Deleted file:{' '}
                            </Box>{' '}
                            {value.deleted}
                        </>
                    )}
                </>
            );
        default:
            return (
                <>
                    <Box mr={'.4rem'}>{fieldLabels[field] || field}: </Box>
                    {value.old && (
                        <>
                            <s style={{ color: COLORS.SLATE_GREY, marginRight: '.4rem' }}>{value.old}</s>→
                        </>
                    )}
                    {value.new || '-'}
                </>
            );
    }
};

const LogEntryRow = ({ log }: { log: LogEntry }) => {
    const { user, oldStatus, newStatus, changes, createdAt } = log;

    return (
        <TableRow>
            <StyledTableCell>
                {user?.name || 'System'}
                <Box>{dayjs(createdAt).format(DATE_FORMATS['DD MMM YYYY HH:mm'])} UTC</Box>
            </StyledTableCell>
            <StyledTableCell>
                <Box mb=".5rem">
                    {oldStatus && newStatus !== oldStatus && <StatusComponent status={oldStatus} />}

                    <>
                        {oldStatus && newStatus !== oldStatus && '→'}
                        <StatusComponent status={newStatus} />
                    </>
                </Box>
                {Object.entries(changes)?.length > 0 &&
                    Object.entries(changes)?.map(([field, value], i) => <Box key={i}>{renderValueChange(field, value)}</Box>)}
            </StyledTableCell>
        </TableRow>
    );
};

const LogsDetails = ({ logs }: { logs: LogEntry[] }) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCellHeader>User</TableCellHeader>
                        <TableCellHeader>Details</TableCellHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {logs?.map(log => <LogEntryRow key={`${log.createdAt}-${log.user?.name || 'system'}`} log={log} />)}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default LogsDetails;
