import { Modal, PrimaryButton, TextField, SecondaryButton, MultipleSelect } from '@get-e/react-components';
import { CardContent, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AxiosError } from 'axios';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import {
    BUTTON_SEND_INVITE,
    INPUT_INVITE_USER_EMAIL,
    INPUT_INVITE_USER_ROLE,
    MODAL_INVITE_USER,
} from '../../../../amplitude/amplKeys';
import { HOTEL_USERS_INVITED } from '../../../../constans/urlPaths';
import { useAuth } from '../../../../context/AuthenticatedUserContext';
import { Severity, useNotificationContext } from '../../../../context/NotificationContext';
import getHelperText from '../../../../helpers/validation/getHelperText';
import getInputError from '../../../../helpers/validation/getInputError';
import InputError from '../../../../helpers/validation/InputError';
import isEmail from '../../../../helpers/validation/validators/isEmail';
import { inviteHotelUser } from '../../../../services/users';

import { useHotelRoles } from '../../../accounts/api/useHotelRoles';

const useStyles = makeStyles({
    heading: { marginBottom: '1rem' },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    buttonCancel: {
        marginLeft: '2em',
    },
    formField: {
        marginBottom: '16px',
    },
});

interface InviteModalProps {
    onClose: () => void;
    isOpen: boolean;
}

const EMAIL_LIST_SEPARATOR = ',';

const InviteModal: FunctionComponent<InviteModalProps> = ({ onClose, isOpen }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const classes = useStyles();
    const autoFocusRef = useRef<HTMLInputElement>();
    const [email, setEmail] = useState('');
    const [roleIds, setRoleIds] = useState<string[]>([]);
    const [emailError, setEmailError] = useState<InputError | null>(null);
    const navigate = useNavigate();
    const { showNotification } = useNotificationContext();
    const { t } = useTranslation();
    const { user } = useAuth();
    const { id = '' } = useParams();

    const hotelId = (id && parseInt(id)) || (user?.hotel.id ?? -1);

    const { hotelRoles, isLoading, isError } = useHotelRoles(hotelId);

    const values = useMemo(() => new Map(hotelRoles?.map(hotelRole => [hotelRole.id.toString(), hotelRole.name])), [hotelRoles]);

    useEffect(() => {
        logAmplitudeEvent(MODAL_INVITE_USER);
    }, []);

    useEffect(() => {
        autoFocusRef.current?.focus();
    }, [autoFocusRef]);

    const { mutate: invitePeopleMutation, isLoading: isLoadingPeople } = useMutation(inviteHotelUser, {
        onSuccess: () => {
            showNotification('Email successfully invited.', Severity.Info);
            onClose();
            navigate(`${HOTEL_USERS_INVITED}/${id}`);
        },
        onError: (error: AxiosError<Error>) => {
            showNotification(error.response?.data.message ?? t('errors.retry'), Severity.Error);
        },
    });

    const handleSubmit = () => {
        logAmplitudeEvent(BUTTON_SEND_INVITE);

        const emailsList = email.split(EMAIL_LIST_SEPARATOR).map(element => element.trim());

        const validates = emailsList.map(emailEntry => {
            return isEmail(emailEntry, InputError.InvalidEmail);
        });

        for (const validated of validates) {
            if (!validated.isValid) {
                setEmailError(getInputError(validated));
                return;
            }
        }

        if (email !== '' && roleIds.length) {
            invitePeopleMutation({
                emails: emailsList,
                roleIds,
            });
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const onSetIds = (ids: string[]) => {
        setRoleIds(ids);
    };

    const disabled = (isLoading && !isError) || !hotelRoles?.length;

    return (
        <Modal open={isOpen} onClose={onClose} fullScreen>
            <CardContent
                sx={{
                    width: isMobile ? '100%' : '600px',
                    margin: '0 auto',
                    paddingLeft: '0',
                }}
            >
                <Typography variant="h2" component="h2" className={classes.heading}>
                    Invite user
                </Typography>
                <TextField
                    value={email}
                    onChange={event => {
                        setEmail(event.target.value);
                        setEmailError(null);
                    }}
                    label="Email"
                    type="text"
                    autoComplete="email"
                    name="email"
                    inputRef={autoFocusRef}
                    required
                    className={classes.formField}
                    error={emailError !== null}
                    helperText={`Add emails one at a time or as a list. ${getHelperText(emailError, t)}`}
                    onFocus={() => logAmplitudeEvent(INPUT_INVITE_USER_EMAIL)}
                />
                <div className={classes.formField}>
                    <MultipleSelect
                        label="Role"
                        value={roleIds}
                        required
                        helperText="The selected role will be given to all invited users."
                        values={values}
                        onSetIds={onSetIds}
                        disabled={disabled}
                        onFocus={() => logAmplitudeEvent(INPUT_INVITE_USER_ROLE)}
                    />
                </div>

                <div>
                    <PrimaryButton onClick={handleSubmit} submitsForm loading={isLoadingPeople} disabled={disabled}>
                        Send Invite
                    </PrimaryButton>
                    <SecondaryButton onClick={handleCancel} className={classes.buttonCancel}>
                        Cancel
                    </SecondaryButton>
                </div>
            </CardContent>
        </Modal>
    );
};

export default InviteModal;
