/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import { PrimaryButton, SecondaryButton, TextField, Autocomplete, Select, SelectOption, Spinner } from '@get-e/react-components';
import { Card, CardContent, Grid, Typography, Button, Box, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { AxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { ChangeEvent, useEffect, useRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import { SCREEN_SINGLE_INVOICE } from '../../../amplitude/amplKeys';
import { COLORS } from '../../../constans/colors';
import { INVOICES } from '../../../constans/urlPaths';
import { useAuth } from '../../../context/AuthenticatedUserContext';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { DATE_FORMATS } from '../../../helpers/dateFormats';
import getHelperText from '../../../helpers/validation/getHelperText';
import InputError from '../../../helpers/validation/InputError';
import isFilledArray from '../../../helpers/validation/validators/isFilledArray';
import isFilledString from '../../../helpers/validation/validators/isFilledString';
import isNotNull from '../../../helpers/validation/validators/isNotNull';
import or from '../../../helpers/validation/validators/or';
import { ReactComponent as Download } from '../../../images/systemDownload.svg';
import { createInvoice, updateInvoice } from '../../../services/invoice';
import { useCustomers } from '../../accounts/api/useCustomers';
import { useHotels } from '../../accounts/api/useHotels';
import { deleteFileFromInvoice } from '../api/deleteInvoice';
import downloadOrPreviewInvoiceFile from '../api/downloadOrPreviewInvoiceFile';
import { Currency, Entity } from '../api/types';
import { DownloadAllFiles } from '../components/files/DownloadAllFiles';
import { FileItem } from '../components/files/FileItem';
import PreviewItem from '../components/files/PreviewItem';
import { PreviewSection } from '../components/files/PreviewSection';
import LogsDetails from '../components/LogsDetails';
import { StatusOption } from '../types';
import { Booking, BookingOption, STATUS_OPTION } from './api/types';
import { useBookingChannels } from './api/useBookingChannels';
import { useCheckInvoiceReference } from './api/useCheckInvoiceReference';
import { useCurrency } from './api/useCurrency';
import { useInvoice } from './api/useInvoice';
import BookingOptions, { parseEuropeanNumber } from './components/BookingOptions';
import { SectionHeader } from './components/SectionHeader';
import { StatusComponent } from './components/StatusComponent';
import { DEFAULT_AIR_ATLANTA, getEnumKeyByValue, getInvoiceDownloadFileName } from './helpers';

const useStyles = makeStyles(() => ({
    mr1: {
        marginRight: '1rem',
    },
    formField: { marginBottom: '1.5rem' },
    chooseFile: {
        textDecoration: 'underline',
        padding: '0 0 1px 0',
        color: COLORS.SLATE_GREY,
        fontSize: '.875rem',
    },
}));

interface FormFields {
    invoiceReference: string;
    comments: string;
    invoiceDate: Dayjs | null;
    dueDate: Dayjs | null;
    totalAmount: string | null;
    customer: Entity | null;
    currency: Currency | null;
    status: string;
    bookingChannel: string;
}

interface FormErrors {
    invoiceReference: InputError | null;
    invoiceDate: InputError | null;
    dueDate: InputError | null;
    totalAmount: InputError | null;
    files: InputError | null;
    currency: InputError | null;
    status: InputError | null;
    customer: InputError | null;
    hotel: InputError | null;
    bookingChannel: InputError | null;
}

interface BookingPageState {
    customer: Entity;
    hotel: Entity;
    bookingOption: BookingOption;
    invoiceDateLocal: string;
}

const UploadInvoice = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user } = useAuth();
    const { showNotification } = useNotificationContext();
    const location = useLocation();
    const state = location.state as BookingPageState;
    const { customer: customerFromBooking, hotel: hotelFromBookingPage, invoiceDateLocal, bookingOption } = state || {};

    const [searchcustomerTerm, setSearchcustomerTerm] = useState('');
    const [searchHotelTerm, setSearchHotelTerm] = useState('');
    const [searchCurrencyTerm, setSearchCurrencyTerm] = useState('');
    const [hotel, setHotel] = useState<Entity | null>(hotelFromBookingPage || (user?.hotel as Entity));
    const [files, setFiles] = useState<File[]>([]);
    const { id = '' } = useParams();
    const isEditDone = useRef(false);
    const [filesForEdit, setFilesForEdit] = useState<Entity[] | any[]>();
    const [filesToDelete, setFilesToDelete] = useState<number[]>([]);
    const [defaultSelectedBookings, setDefaultSelectedBookings] = useState<BookingOption[]>(bookingOption ? [bookingOption] : []);
    const [currentFileId, setCurrentFileId] = useState<number | null>(null);

    const [previewData, setPreviewData] = useState<{
        fileUrl: string;
        fileName: string;
        fileType: string;
    } | null>(null);

    const bookingOptionsRef = useRef<{
        validateFields: () => boolean;
        getSelectedBookingAmount: () => Booking[];
        isTotalAmountSame: boolean;
    } | null>(null);

    const [isDragging, setIsDragging] = useState(false);
    const [currentPreviewIndex, setCurrentPreviewIndex] = useState(0);

    const [formValues, setFormValues] = useState<FormFields>({
        invoiceReference: '',
        comments: '',
        invoiceDate: invoiceDateLocal ? dayjs(invoiceDateLocal) : null,
        dueDate: null,
        totalAmount: '',
        customer: customerFromBooking || DEFAULT_AIR_ATLANTA,
        currency: null,
        status: STATUS_OPTION.UNDER_REVIEW,
        bookingChannel: '',
    });

    const [formErrors, setFormErrors] = useState<FormErrors>({
        invoiceReference: null,
        invoiceDate: null,
        dueDate: null,
        totalAmount: null,
        files: null,
        currency: null,
        status: null,
        customer: null,
        hotel: null,
        bookingChannel: null,
    });

    const [isOpenPicker, setIsOpenPicker] = useState({
        invoiceDate: false,
        dueDate: false,
    });

    const { data: invoiceData, isLoading: isLoadingInvoice } = useInvoice(parseInt(id, 10));
    const { data: currencyData, isLoading: isLoadingCurrency } = useCurrency(searchCurrencyTerm);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data: bookingChannels = [], isLoading: isLoadingBookingChannels } = useBookingChannels();

    useEffect(() => {
        logAmplitudeEvent(SCREEN_SINGLE_INVOICE);
    }, []);

    useEffect(() => {
        if (!invoiceData || isLoadingInvoice) {
            return;
        }

        if (!isEditDone.current && id) {
            setFormValues({
                ...(invoiceData as unknown as FormFields),
                dueDate: invoiceData?.dueDateLocal ? dayjs(invoiceData?.dueDateLocal) : null,
                invoiceDate: invoiceData?.invoiceDateLocal ? dayjs(invoiceData?.invoiceDateLocal) : null,
                status: STATUS_OPTION[invoiceData?.status as keyof typeof STATUS_OPTION],
                bookingChannel: invoiceData?.bookingChannels?.[0]?.id?.toString(),
            });
            setHotel(invoiceData?.hotel);

            setFilesForEdit(invoiceData.files);
            setDefaultSelectedBookings(invoiceData?.bookings);

            previewInvoiceFileMutation({ id: parseInt(id, 10), fileId: invoiceData.files.at(-1)?.id || 0 });
            setCurrentFileId(invoiceData.files.at(-1)?.id || null);

            isEditDone.current = true;
        }
    }, [invoiceData, isLoadingInvoice, id]);

    const { hotels, isLoading: isLoadingHotels } = useHotels(searchHotelTerm, !id);
    const { accounts: customers, isLoading: isLoadingCustomers } = useCustomers(searchcustomerTerm);

    const { data: isInvoiceReferenceUnique = true } = useCheckInvoiceReference({
        hotelId: hotel?.id?.toString() || '',
        search: formValues.invoiceReference,
        isEnabled: formValues.invoiceReference !== invoiceData?.invoiceReference,
    });

    useEffect(() => {
        setFormErrors({
            ...formErrors,
            invoiceReference: isInvoiceReferenceUnique ? null : InputError.InvoiceReferenceExists,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInvoiceReferenceUnique]);

    const { mutate: deleteFileFromInvoiceMutation } = useMutation(deleteFileFromInvoice);

    const { mutate: previewInvoiceFileMutation, isLoading: isLoadingPreview } = useMutation(downloadOrPreviewInvoiceFile, {
        onSuccess: (data: any) => {
            if (data) {
                const fileBlob = data?.data;

                const fileUrl = URL.createObjectURL(fileBlob);
                const fileType = data?.data?.type;

                setPreviewData({
                    fileUrl,
                    fileType,
                    fileName: 'invoice-file',
                });
            }
        },
    });

    const { mutate: createInvoiceMutation, isLoading: isLoadingCreate } = useMutation(createInvoice, {
        onSuccess: () => {
            showNotification('Inovice successfully uploaded', Severity.Info);
            navigate(INVOICES);
        },
        onError: (error: AxiosError<{ message: string }>) => {
            showNotification(error?.response?.data?.message || 'Something went wrong', Severity.Error);
        },
    });

    const { mutate: updateInvoiceMutation, isLoading: isLoadingUpdate } = useMutation(updateInvoice, {
        onSuccess: () => {
            showNotification('Inovice successfully uploaded', Severity.Info);
            navigate(INVOICES);
        },
        onError: (error: AxiosError<{ message: string }>) => {
            showNotification(error?.response?.data?.message || 'Something went wrong', Severity.Error);
        },
    });

    const fileUrls = useMemo(() => {
        const urls = files.map(file => URL.createObjectURL(file));

        return urls;
    }, [files]);

    useEffect(() => {
        return () => {
            fileUrls.forEach(url => URL.revokeObjectURL(url));
        };
    }, [fileUrls]);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPreviewData(null);
        setCurrentFileId(null);
        const selectedFiles = event.target.files;

        if (selectedFiles) {
            setFiles(prevFiles => [...prevFiles, ...Array.from(selectedFiles)]);
            setCurrentPreviewIndex(files.length);
        }

        handleFormErrors('files');
    };

    const handleDrop = (event: any) => {
        event.preventDefault();
        setIsDragging(false);
        const droppedFiles = Array.from(event.dataTransfer.files);

        handleFileChange({ target: { files: droppedFiles as any } } as ChangeEvent<HTMLInputElement>);
    };

    const handleDragOver = (event: any) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleRemoveFile = (fileName: string) => {
        setFiles(prevFiles => prevFiles.filter((file, index) => file.name + index !== fileName));
    };

    const handlePreview = (index: number) => {
        setPreviewData(null);
        setCurrentFileId(null);

        setCurrentPreviewIndex(index);
    };

    const goToPrevious = () => {
        setCurrentPreviewIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : files.length - 1));
    };

    const goToNext = () => {
        setCurrentPreviewIndex(prevIndex => (prevIndex < files.length - 1 ? prevIndex + 1 : 0));
    };

    const handleFormErrors = (name: string) => {
        setFormErrors(prevStateForm => ({
            ...prevStateForm,
            [name]: null,
        }));
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>, inputName?: string) => {
        const { name, value } = event.target;

        setFormValues(prevValues => ({
            ...prevValues,
            [name ?? inputName]: value,
        }));

        handleFormErrors(name ?? inputName);
    };

    const handleAutocompleteChange = <T extends keyof FormFields>(name: T, value: Entity | string | null) => {
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));

        handleFormErrors(name);
    };

    const handleDateChange = (date: Dayjs | null, fieldName: 'invoiceDate' | 'dueDate') => {
        setFormValues(prevValues => ({
            ...prevValues,
            [fieldName]: date,
        }));

        handleFormErrors(fieldName);
    };

    const handlePickerShow = (name: 'invoiceDate' | 'dueDate') => {
        setIsOpenPicker(prevValues => ({
            ...prevValues,
            [name]: !prevValues[name],
        }));
    };

    const handleDeleteFile = (fileId: number) => {
        setPreviewData(null);
        setCurrentFileId(null);

        setFilesForEdit(prevFiles => prevFiles?.filter(file => file.id !== fileId));
        setFilesToDelete([...filesToDelete, fileId]);
    };

    const handleShowEditedFile = (file: Entity) => {
        setCurrentPreviewIndex(-1);
        setCurrentFileId(file.id);

        previewInvoiceFileMutation({
            id: parseInt(id, 10),
            fileId: file.id,
            isDownloading: !(file?.name?.includes('png') || file?.name?.includes('jpg') || file?.name?.includes('pdf')),
            fileName: file.name,
        });
    };

    const handleDownload = (fileId: number, fileName: string) => {
        previewInvoiceFileMutation({
            id: parseInt(id, 10),
            fileId,
            isDownloading: true,
            fileName,
        });
    };

    const validateFields = (): boolean => {
        const validatedReference = isFilledString(formValues.invoiceReference, InputError.Required);

        const validatedDate = isNotNull(formValues.invoiceDate, InputError.Required);
        const validatedDueDate = isNotNull(formValues.dueDate, InputError.Required);

        const validatedAmount = isFilledString(formValues.totalAmount?.toString() || '', InputError.Required);

        const validatedCurrency = isNotNull(formValues.currency, InputError.Required);
        const validatedStatus = isFilledString(formValues.status || '', InputError.Required);
        const validatedCustomer = isFilledString(formValues.customer?.name || '', InputError.Required);
        const validatedHotel = isFilledString(hotel?.name || '', InputError.Required);
        const validatedBookingChannel = isFilledString(formValues?.bookingChannel?.toString() || '', InputError.Required);

        const validatedFiles = id
            ? or(isFilledArray(files, InputError.Required), () => isFilledArray(filesForEdit ?? [], InputError.Required))
            : isFilledArray(files, InputError.Required);

        const fieldErrors: FormErrors = {
            invoiceReference: validatedReference.isValid ? null : validatedReference.error,
            invoiceDate: validatedDate.isValid ? null : validatedDate.error,
            dueDate: validatedDueDate.isValid ? null : validatedDueDate.error,
            totalAmount: validatedAmount.isValid ? null : validatedAmount.error,
            files: validatedFiles.isValid ? null : validatedFiles.error,
            currency: validatedCurrency.isValid ? null : validatedCurrency.error,
            status: validatedStatus.isValid ? null : validatedStatus.error,
            customer: validatedCustomer.isValid ? null : validatedCustomer.error,
            hotel: validatedHotel.isValid ? null : validatedHotel.error,
            bookingChannel: validatedBookingChannel.isValid ? null : validatedBookingChannel.error,
        };

        const isValid = Object.values(fieldErrors).every(error => error === null);
        const isValidSelection = bookingOptionsRef?.current?.validateFields() || true;
        const isTotalAmountSelectionValid = Boolean(bookingOptionsRef?.current?.isTotalAmountSame);

        if (!isValid) {
            setFormErrors(fieldErrors);
        }

        if (isValid && isValidSelection && !isTotalAmountSelectionValid) {
            showNotification('The booking amount does not match the invoice.', Severity.Error);
        }

        return isValid && isValidSelection && isTotalAmountSelectionValid;
    };

    const handleSubmit = () => {
        if (!validateFields()) {
            return;
        }

        const bookings = bookingOptionsRef?.current?.getSelectedBookingAmount();

        const variables = {
            invoiceReference: formValues.invoiceReference,
            invoiceDateLocal: dayjs(formValues.invoiceDate).format(DATE_FORMATS['YYYY-MM-DD']),
            dueDateLocal: dayjs(formValues.dueDate).format(DATE_FORMATS['YYYY-MM-DD']),
            totalAmount:
                typeof formValues.totalAmount === 'number'
                    ? formValues.totalAmount
                    : parseEuropeanNumber(formValues.totalAmount || ''),
            comments: formValues.comments || '',
            currencyId: formValues.currency?.id?.toString() || '',
            files,
            hotelId: hotel?.id?.toString() || '',
            customerId: formValues.customer?.id?.toString() || '',
            status: getEnumKeyByValue(formValues?.status) || '',
            bookings: JSON.stringify(bookings) || '',
            bookingChannelIds: formValues.bookingChannel,
            ...(id && { id }),
        };

        filesToDelete.map(fileId => deleteFileFromInvoiceMutation({ id: parseInt(id, 10), fileId }));

        if (id) {
            updateInvoiceMutation(variables);
        } else {
            createInvoiceMutation(variables);
        }
    };

    const handleBack = () => navigate(-1);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} display={'flex'}>
                <Typography
                    sx={{
                        color: COLORS.BLUE,
                        fontSize: '1.5rem',
                        fontWeight: 700,
                        marginRight: '2rem',
                    }}
                >
                    {id ? `Invoice ${id}` : 'Upload invoice'}
                </Typography>
                {id && invoiceData && <StatusComponent status={invoiceData?.status as StatusOption} />}
            </Grid>
            <Grid item xs={6}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Card variant="outlined">
                            <CardContent>
                                <SectionHeader title="Select customer and hotel" />
                                <Autocomplete
                                    label="Customer"
                                    required
                                    options={customers || []}
                                    getOptionLabel={(option: Entity) => option.name}
                                    renderOption={(prop, option: Entity) => {
                                        return (
                                            <Box component="li" {...prop}>
                                                {option.name}
                                            </Box>
                                        );
                                    }}
                                    value={formValues.customer}
                                    onChange={(_, newValue: Entity | null) => {
                                        handleAutocompleteChange('customer', newValue);
                                    }}
                                    onInputChange={(_, newInputValue) => {
                                        setSearchcustomerTerm(newInputValue);
                                    }}
                                    loading={isLoadingCustomers}
                                    sx={{ marginBottom: '2rem' }}
                                    isError={formErrors.customer !== null}
                                    helperText={getHelperText(formErrors.customer, t)}
                                    disabled={Boolean(id)}
                                />
                                <Autocomplete
                                    label="Hotel"
                                    required
                                    options={(hotels as unknown as Entity[]) || []}
                                    getOptionLabel={(option: Entity) => option.name}
                                    renderOption={(prop, option: Entity) => {
                                        return (
                                            <Box component="li" {...prop}>
                                                {option.name}
                                            </Box>
                                        );
                                    }}
                                    value={hotel}
                                    onChange={(_, newValue: Entity | null) => {
                                        setHotel(newValue);
                                        handleFormErrors('hotel');
                                    }}
                                    onInputChange={(_, newInputValue) => {
                                        setSearchHotelTerm(newInputValue);
                                    }}
                                    loading={isLoadingHotels}
                                    sx={{ marginBottom: '2rem' }}
                                    isError={formErrors.hotel !== null}
                                    helperText={getHelperText(formErrors.hotel, t)}
                                    disabled={Boolean(id)}
                                />
                                <Grid item>
                                    <SectionHeader title="Upload invoices" />
                                    {previewData && (
                                        <PreviewItem
                                            fileUrl={previewData.fileUrl}
                                            fileName={previewData.fileName}
                                            fileType={previewData.fileType}
                                        />
                                    )}
                                    {files.length > 0 && !previewData && (
                                        <PreviewSection
                                            fileUrl={fileUrls?.[currentPreviewIndex]}
                                            fileName={files?.[currentPreviewIndex]?.name}
                                            fileType={files?.[currentPreviewIndex]?.type}
                                            goToPrevious={goToPrevious}
                                            goToNext={goToNext}
                                        />
                                    )}
                                    <Box
                                        sx={{
                                            border: '1px dashed',
                                            borderColor: isDragging ? COLORS.BLUE_DARK : COLORS.SLATE_GREY,
                                            borderRadius: '8px',
                                            padding: '1rem',
                                            textAlign: 'center',
                                            backgroundColor: isDragging ? COLORS.LIGHT_BLUE : 'transparent',
                                            cursor: 'pointer',
                                            marginTop: '1rem',
                                            marginBottom: '1rem',
                                        }}
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleDragLeave}
                                        onDrop={handleDrop}
                                    >
                                        <Download />
                                        <Typography fontSize=".875rem" marginBottom="0.5rem" color={COLORS.SLATE_GREY}>
                                            Drag and drop or{' '}
                                            <Button component="label" className={classes.chooseFile}>
                                                choose your files
                                                <input type="file" multiple hidden onChange={handleFileChange} />
                                            </Button>
                                        </Typography>
                                        {formErrors.files !== null && (
                                            <Typography marginTop="1rem" sx={{ color: COLORS.RED, fontSize: '0.75rem' }}>
                                                * Minimum 1 file required
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box>
                                        {filesForEdit?.map((file, index) => (
                                            <FileItem
                                                key={index}
                                                file={file}
                                                handlePreview={() => handleShowEditedFile(file)}
                                                handleRemove={() => handleDeleteFile(file.id)}
                                                isSelected={
                                                    file.id === currentFileId &&
                                                    (file?.name?.includes('png') ||
                                                        file?.name?.includes('jpg') ||
                                                        file?.name?.includes('pdf'))
                                                }
                                                handleDownload={() => handleDownload(file.id, file.name)}
                                            />
                                        ))}
                                    </Box>
                                    <Box>
                                        {files?.map((file, index) => (
                                            <FileItem
                                                key={index}
                                                file={file}
                                                handlePreview={() => handlePreview(index)}
                                                handleRemove={() => handleRemoveFile(file.name + index)}
                                                isSelected={
                                                    currentPreviewIndex === index &&
                                                    (file?.type?.includes('image') || file?.type?.includes('pdf'))
                                                }
                                            />
                                        ))}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginBottom: '1rem',
                                            gap: '2rem',
                                        }}
                                    >
                                        {filesForEdit && filesForEdit?.length > 0 && id && (
                                            <DownloadAllFiles
                                                id={id}
                                                invoiceFileName={getInvoiceDownloadFileName(
                                                    hotel?.name || '',
                                                    formValues?.invoiceDate,
                                                    id
                                                )}
                                                fileId={filesForEdit?.length === 1 ? filesForEdit[0]?.id : null}
                                            />
                                        )}
                                        {isLoadingPreview && <Spinner size={12} />}
                                    </Box>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={6}>
                <Card variant="outlined">
                    <CardContent>
                        <SectionHeader title="Invoice details" sx={{ marginBottom: '.5rem !important' }} />
                        <Grid item xs marginBottom={'1rem'}>
                            {!id || invoiceData?.bookingChannels || !bookingChannels ? (
                                <Select
                                    label="Booking channel"
                                    value={formValues.bookingChannel}
                                    onChangeValue={newValue => handleAutocompleteChange('bookingChannel', newValue)}
                                    required
                                    error={formErrors.bookingChannel !== null}
                                    helperText={getHelperText(formErrors.bookingChannel, t)}
                                >
                                    {bookingChannels?.map(el => (
                                        <SelectOption key={el.id} value={el.id}>
                                            {el.name}
                                        </SelectOption>
                                    ))}
                                </Select>
                            ) : (
                                <Skeleton variant="rectangular" width="100%" height={56} />
                            )}
                        </Grid>
                        <TextField
                            label="Invoice reference"
                            name="invoiceReference"
                            value={formValues.invoiceReference}
                            onChange={handleInputChange}
                            autoComplete="off"
                            required
                            error={formErrors.invoiceReference !== null}
                            helperText={getHelperText(formErrors.invoiceReference, t)}
                            className={formErrors.invoiceReference !== null ? '' : classes.formField}
                        />
                        <DatePicker
                            label="Invoice date"
                            value={formValues.invoiceDate}
                            onChange={date => handleDateChange(date, 'invoiceDate')}
                            format={DATE_FORMATS['DD MMM YYYY']}
                            slotProps={{
                                textField: {
                                    variant: 'filled',
                                    required: true,
                                    onClick: () => handlePickerShow('invoiceDate'),
                                    error: formErrors.invoiceDate !== null,
                                    helperText: getHelperText(formErrors.invoiceDate, t),
                                },
                            }}
                            open={isOpenPicker.invoiceDate}
                            onClose={() => handlePickerShow('invoiceDate')}
                            className={formErrors.invoiceDate !== null ? '' : classes.formField}
                            sx={{ width: '100%' }}
                        />
                        <DatePicker
                            label="Due date"
                            value={formValues.dueDate}
                            onChange={date => handleDateChange(date, 'dueDate')}
                            format={DATE_FORMATS['DD MMM YYYY']}
                            slotProps={{
                                textField: {
                                    variant: 'filled',
                                    required: true,
                                    onClick: () => handlePickerShow('dueDate'),
                                    error: formErrors.dueDate !== null,
                                    helperText: getHelperText(formErrors.dueDate, t),
                                },
                            }}
                            open={isOpenPicker.dueDate}
                            onClose={() => handlePickerShow('dueDate')}
                            className={formErrors.dueDate !== null ? '' : classes.formField}
                            sx={{ width: '100%' }}
                        />
                        <SectionHeader title="Amount" />
                        <Autocomplete
                            label="Currency"
                            options={currencyData || []}
                            getOptionLabel={(option: Currency) => `${option.iso} - ${option.name}`}
                            renderOption={(prop, option: Currency) => {
                                return (
                                    <Box component="li" {...prop}>
                                        {option.iso} - {option.name}
                                    </Box>
                                );
                            }}
                            value={formValues.currency}
                            onChange={(_, newValue: Currency | null) => {
                                handleAutocompleteChange('currency', newValue);
                            }}
                            onInputChange={(_, newInputValue) => {
                                setSearchCurrencyTerm(newInputValue);
                            }}
                            loading={isLoadingCurrency}
                            sx={{ marginBottom: '1.5rem' }}
                            required
                            isError={formErrors.currency !== null}
                            helperText={getHelperText(formErrors.currency, t)}
                        />
                        <NumericFormat
                            customInput={TextField}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            // prefix={`${formValues.currency?.iso || ''} `}
                            fullWidth
                            label="Invoice total amount"
                            required
                            error={formErrors.totalAmount !== null}
                            helperText={getHelperText(formErrors.totalAmount, t)}
                            value={formValues.totalAmount}
                            name="totalAmount"
                            onChange={handleInputChange}
                            autoComplete="off"
                            sx={{ paddingBottom: '1.25rem' }}
                        />
                        <SectionHeader title="Status" />
                        <TextField
                            label="Comments (optional)"
                            name="comments"
                            value={formValues.comments}
                            onChange={handleInputChange}
                            InputProps={{
                                minRows: '3',
                                multiline: true,
                            }}
                            placeholder="Add any comments here"
                        />
                        <Select
                            label="Status"
                            value={formValues.status}
                            onChangeValue={newValue => handleAutocompleteChange('status', newValue)}
                            required
                            error={formErrors.status !== null}
                            helperText={getHelperText(formErrors.status, t)}
                        >
                            {Object.keys(STATUS_OPTION).map(status => (
                                <SelectOption key={status} value={STATUS_OPTION[status as keyof typeof STATUS_OPTION]}>
                                    {STATUS_OPTION[status as keyof typeof STATUS_OPTION]}
                                </SelectOption>
                            ))}
                        </Select>
                        {invoiceData?.customerComment && (
                            <>
                                <SectionHeader
                                    title="Customer comment"
                                    sx={{ marginBottom: '.5rem !important', marginTop: '1rem !important' }}
                                />
                                <Typography>{invoiceData?.customerComment}</Typography>
                            </>
                        )}
                    </CardContent>
                </Card>
                <Box sx={{ marginTop: '2rem' }}>
                    <SectionHeader title="Logs" sx={{ marginBottom: '.5rem !important' }} />
                    <LogsDetails logs={invoiceData?.logs} />
                </Box>
            </Grid>
            <BookingOptions
                hotelId={hotel?.id}
                customerId={formValues.customer?.id}
                currency={formValues.currency?.iso}
                invoiceTotalAmount={formValues.totalAmount}
                ref={bookingOptionsRef}
                defaultSelectedBookings={defaultSelectedBookings}
                isEditing={Boolean(id) || Boolean(bookingOption)}
                invoiceId={id}
            />
            <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
                <PrimaryButton onClick={handleSubmit} className={classes.mr1} loading={isLoadingCreate || isLoadingUpdate}>
                    {id ? 'Update' : 'Upload'}
                </PrimaryButton>
                <SecondaryButton onClick={handleBack}>Back</SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default UploadInvoice;
