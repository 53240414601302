import { PrimaryButton, SecondaryButton, Form, TextField, MessageDialog } from '@get-e/react-components';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AxiosError } from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import {
    BUTTON_SAVE_CUSTOMER,
    SCREEN_CREATE_EDIT_CUSTOMER,
    INPUT_ACCOUNT_ADDRESS,
    INPUT_ACCOUNT_NAME,
} from '../../../amplitude/amplKeys';
import { GooglePlacesAutocomplete } from '../../../components/GooglePlacesAutocomplete';
import PageTitle from '../../../components/PageTitle';
import { LOCAL_STORAGE_KEYS } from '../../../constans/localStorageKeys';
import { ACCOUNTS_CUSTOMERS, PEOPLE_ACTIVE } from '../../../constans/urlPaths';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { mapAddressToAddressRequest } from '../../../helpers/maps/mapAddressToAddressRequest';
import allValid from '../../../helpers/validation/allValid';
import getHelperText from '../../../helpers/validation/getHelperText';
import InputError from '../../../helpers/validation/InputError';
import isFilledObject from '../../../helpers/validation/validators/isFilledObject';
import isFilledString from '../../../helpers/validation/validators/isFilledString';
import { createCustomer, updateCustomer } from '../../../services/customer';
import { AddressRequest } from '../api/types';
import { useCustomer } from '../api/useCustomer';
import AddEditPaymentMethodModal from '../paymentMethod/AddEditPaymentMethodModal';
import DirectBillingPaymentMethod from '../paymentMethod/DirectBillingPaymentMethod';

const useStyles = makeStyles({
    buttonCancel: {
        marginLeft: '2rem',
    },
    title: {
        marginBottom: '3rem',
    },
});

const AddEditCustomer = () => {
    const autoFocusRef = useRef<HTMLInputElement>();
    const [name, setName] = useState('');
    const [address, setAddress] = useState<AddressRequest | string>({} as AddressRequest);
    const [addressError, setAddressError] = useState<InputError | null>(null);
    const [customerError, setCustomerError] = useState<InputError | null>(null);

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const { id = '' } = useParams();
    const { t } = useTranslation();
    const { showNotification } = useNotificationContext();
    const { usePaymentMethodOnAccounts } = useFlags();

    const { data: customerData, isFetching: isFetchingAccount } = useCustomer(parseInt(id, 10));

    useEffect(() => {
        if (!isFetchingAccount) {
            autoFocusRef.current?.focus();
        }
    }, [autoFocusRef, isFetchingAccount]);

    useEffect(() => {
        if (!customerData) {
            return;
        }

        setName(customerData.name);

        location.state = {
            name: customerData.name,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerData]);

    useEffect(() => {
        logAmplitudeEvent(SCREEN_CREATE_EDIT_CUSTOMER);
    }, []);

    useEffect(() => {
        logAmplitudeEvent(INPUT_ACCOUNT_ADDRESS);
    }, [address]);

    const { mutate: createMutation, isLoading: isLoadingCreate } = useMutation(createCustomer, {
        onSuccess: () => {
            navigate(ACCOUNTS_CUSTOMERS);
            showNotification('Customer successfully created.', Severity.Info);
        },
        onError: (error: AxiosError<{ message: string }>) => {
            showNotification(error?.response?.data?.message || t('errors.retry'), Severity.Error);
        },
    });

    const { mutate: updateMutation, isLoading: isLoadingEdit } = useMutation(updateCustomer, {
        onSuccess: () => {
            navigate(ACCOUNTS_CUSTOMERS);
            showNotification('Customer successfully edited.', Severity.Info);
        },
        onError: (error: AxiosError<{ message: string }>) => {
            setIsOpenModal(false);
            showNotification(error?.response?.data?.message || t('errors.retry'), Severity.Error);
        },
    });

    const validateFormFields = (): boolean => {
        const validated = {
            name: isFilledString(name, InputError.Required),
            address: isFilledObject(address, InputError.Required),
        };

        if (!allValid(validated)) {
            setAddressError(validated.address.isValid ? null : validated.address?.error);
            setCustomerError(validated.name.isValid ? null : validated.name.error);
            return false;
        }

        return true;
    };

    const handleSubmit = () => {
        logAmplitudeEvent(BUTTON_SAVE_CUSTOMER);

        if (validateFormFields()) {
            if (id) {
                setIsOpenModal(true);
            } else {
                createMutation({
                    name,
                    address: address as AddressRequest,
                });
            }
        }
    };

    const handleSave = () => {
        const addressRequest = mapAddressToAddressRequest(address);

        if (id) {
            updateMutation({ id: parseInt(id, 10), name, address: addressRequest });
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    const handlePaymentModal = () => {
        setIsPaymentModalOpen(!isPaymentModalOpen);
    };

    return (
        <Grid container display={'flex'} justifyContent={'start'} flexDirection={'row'} columnSpacing={4}>
            <Grid item xs={12} md={6} xl={6}>
                <Grid item display={'flex'} justifyContent={'space-between'} marginBottom={'1rem'}>
                    <PageTitle title={id ? 'Edit customer' : 'Create customer'} />
                    {id && (
                        <Box>
                            <SecondaryButton
                                onClick={() => {
                                    window.localStorage.setItem(LOCAL_STORAGE_KEYS.ACCOUNT_USERS, customerData?.name ?? '');
                                    navigate(`${PEOPLE_ACTIVE}/${id}`);
                                }}
                            >
                                Users
                            </SecondaryButton>
                        </Box>
                    )}
                </Grid>
                <Form onSubmit={handleSubmit}>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid container columnSpacing={1} rowSpacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        value={name}
                                        onChange={event => setName(event.target.value)}
                                        label="Account name"
                                        type="text"
                                        autoFocus
                                        autoComplete="username"
                                        name="name"
                                        required
                                        disabled={isFetchingAccount}
                                        helperText={getHelperText(customerError, t)}
                                        error={customerError !== null}
                                        inputRef={autoFocusRef}
                                        onFocus={() => logAmplitudeEvent(INPUT_ACCOUNT_NAME)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <GooglePlacesAutocomplete
                                        error={addressError}
                                        helperText={getHelperText(addressError, t)}
                                        onChangeError={setAddressError}
                                        onChangeAddress={setAddress}
                                        label="Address"
                                        defaultValue={customerData?.address}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Grid item xs={12} marginTop="2rem">
                        <PrimaryButton
                            onClick={handleSubmit}
                            submitsForm
                            loading={isLoadingCreate || isLoadingEdit || isFetchingAccount}
                        >
                            {id ? 'Save' : 'Create'}
                        </PrimaryButton>
                        <SecondaryButton onClick={handleCancel} className={classes.buttonCancel}>
                            {id ? 'Back' : 'Cancel'}
                        </SecondaryButton>
                    </Grid>
                </Form>
            </Grid>
            {id && usePaymentMethodOnAccounts && (
                <Grid item xs={12} md={6}>
                    <DirectBillingPaymentMethod />
                </Grid>
            )}
            {isOpenModal && (
                <MessageDialog
                    title="Are you sure?"
                    onClose={() => setIsOpenModal(false)}
                    onConfirm={handleSave}
                    closeButtonLabel="Cancel"
                    confirmButtonLabel={id ? 'Save' : 'Create'}
                    isConfirmButtonDisplayed
                    closeButtonType="secondary"
                >
                    <Typography sx={{ padding: '1rem 0' }}>
                        You are about to {id ? 'edit' : 'create'} this customer. <br />
                        <strong>Are you sure you want to proceed?</strong>
                    </Typography>
                </MessageDialog>
            )}
            {isPaymentModalOpen && <AddEditPaymentMethodModal isModalOpen={isPaymentModalOpen} onClose={handlePaymentModal} />}
        </Grid>
    );
};

export default AddEditCustomer;
