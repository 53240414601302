/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps */
import { Search } from '@mui/icons-material';
import {
    Box,
    Card,
    CardContent,
    Grid,
    StandardTextFieldProps,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Paper,
    TableFooter,
    InputAdornment,
    CircularProgress,
    Checkbox,
    FormControlLabel,
    Switch,
} from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { DateRange, DateRangePicker, SingleInputDateTimeRangeField } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useRef, useState, forwardRef, useImperativeHandle, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

import { InvoicedTooltip } from '../../../../components/InvoicedTooltip';
import { COLORS } from '../../../../constans/colors';
import { BOOKINGS } from '../../../../constans/urlPaths';
import { DATE_FORMATS } from '../../../../helpers/dateFormats';
import { getPriceNumberWithDecimals } from '../../../../helpers/getPriceNumberWithDecimals';
import getHelperText from '../../../../helpers/validation/getHelperText';
import InputError from '../../../../helpers/validation/InputError';
import isFilledString from '../../../../helpers/validation/validators/isFilledString';
import { dateTimeRangePickerStyles, dayStyles, textFieldStyles } from '../../../../styles/DateTimeRangePicker';
import StatusButton from '../../../reservations/booking/bookingCard/StatusButton';
import { BookingOption } from '../api/types';
import { useBookingOptions } from '../api/useBookingOptions';
import { isThisMonthRange, mapToBookingStatus } from '../helpers';
import { SectionHeader } from './SectionHeader';

let searchTimeoutId: ReturnType<typeof setTimeout> | number = -1;

const StyledTableHead = styled(TableHead)(() => ({
    '& .MuiTableCell-root': {
        fontWeight: 400,
    },
}));

const useStyles = makeStyles(() => ({
    greenInput: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: '2px',
                borderColor: COLORS.GREEN,
            },
            '&:hover fieldset': {
                borderColor: COLORS.GREEN,
            },
            '&.Mui-focused fieldset': {
                borderColor: COLORS.GREEN,
            },
        },
    },
    redInput: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: '2px',
                borderColor: COLORS.RED,
            },
            '&:hover fieldset': {
                borderColor: COLORS.RED,
            },
            '&.Mui-focused fieldset': {
                borderColor: COLORS.RED,
            },
        },
    },
    status: {
        fontSize: '10px !important',
        padding: '4px !important',
    },
}));

interface BookingError {
    id: number;
    error: InputError | null;
}

interface BookingOptionsProps {
    hotelId?: number;
    customerId?: number;
    currency?: string;
    invoiceTotalAmount?: string | null;
    defaultSelectedBookings?: BookingOption[] | null;
    isEditing?: boolean;
    invoiceId?: string;
}

export function parseEuropeanNumber(input: string): number {
    const normalized = input?.replace(/\./g, '').replace(',', '.'); // replace , and . into .

    return parseFloat(normalized);
}

const BookingOptions = forwardRef((props: BookingOptionsProps, ref) => {
    const { hotelId, customerId, currency, invoiceTotalAmount, defaultSelectedBookings, isEditing, invoiceId } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const [searchBookingTerm, setSearchBookingTerm] = useState('');
    const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState('');
    const [showOnlyChecked, setShowOnlyChecked] = useState(isEditing);
    const [checkedBookings, setCheckedBookings] = useState<Record<number, BookingOption>>({});
    const [completedOptions, setCompletedOptions] = useState<BookingOption[]>();

    const [selectedDateRange, setSelectedDateRange] = useState<DateRange<Dayjs>>(
        isEditing ? [null, null] : [dayjs().startOf('month'), dayjs().endOf('month')]
    );

    const errorsBookingList = useRef<BookingError[]>([]);

    const {
        data: bookingOptions,
        isLoading,
        isFetching,
    } = useBookingOptions({
        hotelId: hotelId?.toString() ?? '',
        customerId: customerId?.toString() ?? '',
        search: debouncedSearchPhrase,
        fromDateLocal: selectedDateRange?.[0]?.format(DATE_FORMATS['YYYY-MM-DD']),
        toDateLocal: selectedDateRange?.[1]?.format(DATE_FORMATS['YYYY-MM-DD']),
        isDisabled: showOnlyChecked,
    });

    useEffect(() => {
        const checkedBookingOptionsArray = Object.values(checkedBookings);

        const newBookingOptions = checkedBookingOptionsArray.filter(checkedBooking => {
            return !bookingOptions.some(existingBooking => existingBooking.id === checkedBooking.id);
        });

        const updatedBookingOptions = [...bookingOptions, ...newBookingOptions];

        setCompletedOptions(updatedBookingOptions);
    }, [bookingOptions]);

    useEffect(() => {
        defaultSelectedBookings?.forEach(booking => {
            if (!checkedBookings[booking.id]) {
                setCheckedBookings(prev => ({
                    ...prev,
                    [booking.id]: booking,
                }));
            }
        });
    }, [defaultSelectedBookings]);

    const handleSearchBookingInputOnChange = useCallback(
        (value: string) => {
            setSearchBookingTerm(value);
            clearTimeout(searchTimeoutId);
            searchTimeoutId = setTimeout(() => {
                setDebouncedSearchPhrase(value);
            }, 600);
        },
        [setDebouncedSearchPhrase]
    );

    const handleCheckboxChange = (booking: BookingOption, isChecked: boolean) => {
        setCheckedBookings(prev => {
            const newCheckedBookings = { ...prev };
            let isMutiple = false;

            if (isChecked) {
                newCheckedBookings[booking.id] = booking;

                if (Object.keys(prev).length === 0 && invoiceTotalAmount && !isEditing) {
                    newCheckedBookings[booking.id].amount = invoiceTotalAmount || '';
                } else if (Object.keys(prev).length > 0 && invoiceTotalAmount && !isEditing && !isMutiple) {
                    isMutiple = true;
                    Object.keys(newCheckedBookings).forEach(key => {
                        newCheckedBookings[Number(key)].amount = '';
                    });
                }
            } else {
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete newCheckedBookings[booking.id];
                errorsBookingList.current = errorsBookingList.current.filter(error => error.id !== booking.id);
            }

            return newCheckedBookings;
        });
    };

    const filteredBookings = showOnlyChecked
        ? completedOptions?.filter(booking => booking.id in checkedBookings)
        : completedOptions;

    const handleAmountChange = (booking: BookingOption, amount: string) => {
        const bookingId = booking.id;

        setCheckedBookings(prev => {
            const updatedCheckedBookings = { ...prev };

            if (updatedCheckedBookings[bookingId]) {
                updatedCheckedBookings[bookingId].amount = amount;
            } else {
                updatedCheckedBookings[bookingId] = booking;
                updatedCheckedBookings[bookingId].amount = amount;
            }

            errorsBookingList.current = errorsBookingList.current.filter(error => error.id !== bookingId);
            return updatedCheckedBookings;
        });
    };

    const calculateTotalAmount = () => {
        return Object.values(checkedBookings).reduce(
            (sum, booking) =>
                sum + (typeof booking.amount === 'number' ? booking.amount : parseEuropeanNumber(booking.amount || '') || 0),
            0
        );
    };

    const validateFields = () => {
        errorsBookingList.current = [];

        const findEmptyBookingAmounts = Object.values(checkedBookings)
            .filter(booking => {
                return !isFilledString(booking.amount?.toString() ?? '', InputError.Required)?.isValid;
            })
            .map(booking => booking.id);

        findEmptyBookingAmounts.forEach(id => {
            errorsBookingList.current.push({
                id,
                error: InputError.Required,
            });
        });

        return errorsBookingList.current.length === 0;
    };

    const getInvoiceTooltipIds = (financeInvoiceBookings?: number[]) => {
        const invoiceIds = financeInvoiceBookings?.filter(el => el !== parseInt(invoiceId || ''));

        if (!invoiceIds || invoiceIds?.length === 0) {
            return;
        }

        return <InvoicedTooltip invoiceIds={invoiceIds} />;
    };

    const isTotalMatching = useMemo(() => {
        return getPriceNumberWithDecimals(invoiceTotalAmount || '') === getPriceNumberWithDecimals(calculateTotalAmount());
    }, [invoiceTotalAmount, checkedBookings]);

    useImperativeHandle(ref, () => ({
        validateFields,
        getSelectedBookingAmount: () => {
            return Object.values(checkedBookings)?.map(({ id, amount }) => ({
                id,
                amount: typeof amount === 'number' ? amount : parseEuropeanNumber(amount || ''),
            }));
        },
        isTotalAmountSame: Object.keys(checkedBookings)?.length > 0 ? isTotalMatching : true,
    }));

    return (
        <Grid
            item
            sx={{
                width: '100%',
            }}
        >
            <Card variant="outlined">
                <CardContent>
                    <SectionHeader title="Select bookings" />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} xl={4}>
                            <DateRangePicker
                                sx={dateTimeRangePickerStyles}
                                label={isThisMonthRange(selectedDateRange) ? 'This Month' : 'Date range'}
                                value={selectedDateRange}
                                onChange={newValue => setSelectedDateRange(newValue)}
                                slotProps={{
                                    textField: { ...(textFieldStyles as StandardTextFieldProps) },
                                    field: { clearable: true },
                                    day: {
                                        sx: dayStyles,
                                    },
                                }}
                                slots={{ field: SingleInputDateTimeRangeField }}
                                format={DATE_FORMATS['DD MMM YYYY']}
                            />
                        </Grid>
                        <Grid item xs={12} md={8} xl={8}>
                            <TextField
                                label="Search booking number, confirmation number, name"
                                value={searchBookingTerm}
                                onChange={event => {
                                    handleSearchBookingInputOnChange(event.target.value);
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <>{isLoading || isFetching ? <CircularProgress color="inherit" size={20} /> : null}</>
                                    ),
                                }}
                                variant="filled"
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper} sx={{ marginTop: '1rem', minHeight: '200px', maxHeight: '400px' }}>
                        <Table stickyHeader>
                            <StyledTableHead>
                                <TableRow>
                                    <TableCell>Booking Number</TableCell>
                                    <TableCell>Confirmation Number</TableCell>
                                    <TableCell>Check-in (local)</TableCell>
                                    <TableCell>Check-out (local)</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </StyledTableHead>
                            <TableBody sx={{ overflowY: 'auto', maxHeight: '300px' }}>
                                {filteredBookings?.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                height={200}
                                                color={COLORS.SLATE_GREY}
                                            >
                                                <Search />
                                                <Typography variant="body1">Search a booking and add it to the list</Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <>
                                        {filteredBookings?.map(booking => (
                                            <TableRow key={booking.id}>
                                                <TableCell
                                                    sx={{
                                                        color: COLORS.BLUE_TEXT,
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={event => {
                                                        event.stopPropagation();
                                                        window.open(`${BOOKINGS}/${booking?.id}`, '_blank');
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '0.5rem',
                                                        }}
                                                    >
                                                        <Typography>{booking.id}</Typography>
                                                        {getInvoiceTooltipIds(booking?.financeInvoiceBookings)}
                                                    </Box>
                                                </TableCell>
                                                <TableCell>{booking.confirmationNumber}</TableCell>
                                                <TableCell>
                                                    {dayjs(booking.checkInDateLocal).format(DATE_FORMATS['DD MMM YYYY HH:mm'])}
                                                </TableCell>
                                                <TableCell>
                                                    {dayjs(booking.checkOutDateLocal).format(DATE_FORMATS['DD MMM YYYY HH:mm'])}
                                                </TableCell>
                                                <TableCell>
                                                    <span style={{ color: COLORS.SLATE_GREY }}>
                                                        {booking.guest.employeeNumber}
                                                    </span>{' '}
                                                    {booking.guest.name}{' '}
                                                </TableCell>
                                                <TableCell>
                                                    <NumericFormat
                                                        customInput={TextField}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        // prefix={`${currency || ''} `}
                                                        fullWidth
                                                        value={checkedBookings[booking.id]?.amount?.toLocaleString() ?? ''}
                                                        onChange={e => handleAmountChange(booking, e.target.value)}
                                                        size="small"
                                                        variant="filled"
                                                        label="Input amount"
                                                        required={booking.id in checkedBookings}
                                                        error={Boolean(
                                                            errorsBookingList?.current?.find(item => item.id === booking.id)
                                                        )}
                                                        helperText={
                                                            errorsBookingList?.current?.find(item => item.id === booking.id)
                                                                ?.error
                                                                ? getHelperText(
                                                                      errorsBookingList?.current?.find(
                                                                          item => item.id === booking.id
                                                                      )?.error as InputError,
                                                                      t
                                                                  )
                                                                : ''
                                                        }
                                                        autoComplete="off"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <StatusButton
                                                        variant={mapToBookingStatus(booking.status)}
                                                        className={classes.status}
                                                    >
                                                        {mapToBookingStatus(booking.status) as string}
                                                    </StatusButton>
                                                </TableCell>

                                                <TableCell>
                                                    <Checkbox
                                                        checked={booking.id in checkedBookings}
                                                        onChange={event => handleCheckboxChange(booking, event.target.checked)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                )}
                            </TableBody>
                            {/* FOOTER */}
                            {(completedOptions?.length !== 0 || isEditing) && (
                                <TableFooter
                                    sx={{
                                        position: 'sticky',
                                        bottom: 0,
                                        backgroundColor: 'white',
                                        zIndex: 1,
                                        boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <TableRow>
                                        <TableCell colSpan={1} align="left">
                                            <Typography>Total</Typography>
                                        </TableCell>
                                        <TableCell colSpan={7} align="right">
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {!isTotalMatching && (
                                                    <Typography
                                                        sx={{ fontSize: '12px', marginRight: '.5rem', color: COLORS.RED }}
                                                    >
                                                        Not matching invoice total amount
                                                    </Typography>
                                                )}
                                                <TextField
                                                    value={getPriceNumberWithDecimals(calculateTotalAmount())}
                                                    size="small"
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">{currency}</InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            // total amount
                                                            <InputAdornment position="start">
                                                                &nbsp;/{getPriceNumberWithDecimals(invoiceTotalAmount || '')}
                                                            </InputAdornment>
                                                        ),
                                                        readOnly: true,
                                                        sx: {
                                                            textAlign: 'right',
                                                        },
                                                        inputProps: { style: { textAlign: 'right' } },
                                                    }}
                                                    hiddenLabel
                                                    className={!isTotalMatching ? classes.redInput : classes.greenInput}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={showOnlyChecked}
                                                            onChange={event => setShowOnlyChecked(event.target.checked)}
                                                        />
                                                    }
                                                    label="Show only selected"
                                                    labelPlacement="start"
                                                    sx={{ marginLeft: '3rem' }}
                                                />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            )}
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Grid>
    );
});

export default BookingOptions;
