/* eslint-disable @typescript-eslint/no-shadow */
import { PrimaryButton } from '@get-e/react-components';
import { Add } from '@mui/icons-material';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import PageTitle from '../../../components/PageTitle';
import PaymentMethodList from '../../../components/PaymentMethodList';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { PaymentMethod } from '../api/types';
import { usePaymentMethods } from '../api/usePaymentMethods';
import AddEditPaymentMethodModal from './AddEditPaymentMethodModal';

const useStyles = makeStyles({
    buttonCancel: {
        marginLeft: '2rem',
    },
    header: {
        display: 'flex',
        marginBottom: '0',
        alignItems: 'center',
    },
});

interface PaymentMethodProps {
    isPageEditHotelAccount?: boolean;
}

const DirectBillingPaymentMethod = ({ isPageEditHotelAccount }: PaymentMethodProps) => {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(null);

    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

    const { showNotification } = useNotificationContext();
    const { t } = useTranslation();
    const classes = useStyles();
    const { id = '' } = useParams();

    const { data: paymentMethods = [], refetch: refetchPayments } = usePaymentMethods({
        ...(isPageEditHotelAccount ? { hotelId: id } : { customerId: id }),
    });

    const handlePaymentModal = () => {
        setIsPaymentModalOpen(!isPaymentModalOpen);
    };

    const onSuccessPayment = async () => {
        try {
            await refetchPayments();
        } catch (error) {
            showNotification(t('errors.retry'), Severity.Error);
        }
    };

    const onEditPaymentMethod = (id: number) => {
        const payment = paymentMethods?.find(el => el.id === id);

        setSelectedPaymentMethod(payment || null);
        handlePaymentModal();
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem' }}>
                <PageTitle
                    title="Direct billing payment method"
                    level={3}
                    className={classes.header}
                    key={paymentMethods.length}
                />
                <PrimaryButton
                    onClick={() => {
                        setSelectedPaymentMethod(null);
                        handlePaymentModal();
                    }}
                    icon={<Add />}
                >
                    Add
                </PrimaryButton>
            </Box>
            <PaymentMethodList
                data={paymentMethods}
                onEdit={(id: number) => onEditPaymentMethod(id)}
                onSuccess={onSuccessPayment}
                isPageEditHotelAccount={isPageEditHotelAccount}
            />
            {isPaymentModalOpen && (
                <AddEditPaymentMethodModal
                    hotelId={isPageEditHotelAccount ? parseInt(id) : undefined}
                    customerId={isPageEditHotelAccount ? undefined : parseInt(id)}
                    isModalOpen={isPaymentModalOpen}
                    onClose={handlePaymentModal}
                    onSuccess={onSuccessPayment}
                    selectedPaymentMethod={selectedPaymentMethod}
                    isPageEditHotelAccount={isPageEditHotelAccount}
                />
            )}
        </>
    );
};

export default DirectBillingPaymentMethod;
