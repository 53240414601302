import dayjs, { Dayjs } from 'dayjs';
import React, { createContext, useContext, useState } from 'react';

interface InvoiceFiltersContextType {
    searchPhrase: string;
    selectedDateRange: [Dayjs | null, Dayjs | null];
    statusIds: string[];
    defaultStatusIds: string[];
    bookingChannel: string[];
    defaultBookingChannel: string[];
    filters: { cancelledBooking: boolean };
    page: number;
    setFiltersState: (filters: Partial<InvoiceFiltersContextType>) => void;
}

const InvoiceFiltersContext = createContext<InvoiceFiltersContextType | undefined>(undefined);

export const FiltersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [filtersState, setFiltersState] = useState<Omit<InvoiceFiltersContextType, 'setFiltersState'>>({
        searchPhrase: '',
        selectedDateRange: [dayjs().startOf('month'), dayjs().endOf('month')],
        statusIds: [],
        bookingChannel: [],
        defaultStatusIds: [],
        defaultBookingChannel: [],
        filters: { cancelledBooking: false },
        page: 0,
    });

    const updateFilters = (newFilters: Partial<Omit<InvoiceFiltersContextType, 'setFiltersState'>>) => {
        setFiltersState(prev => ({
            ...prev,
            ...newFilters,
            filters: { ...prev.filters, ...newFilters.filters },
            statusIds: newFilters.statusIds ? newFilters.statusIds : prev.statusIds,
            defaultStatusIds: newFilters.statusIds ?? prev.statusIds,
            bookingChannel: newFilters.bookingChannel ? newFilters.bookingChannel : prev.bookingChannel,
            defaultBookingChannel: newFilters.bookingChannel ?? prev.bookingChannel,
        }));
    };

    return (
        <InvoiceFiltersContext.Provider value={{ ...filtersState, setFiltersState: updateFilters }}>
            {children}
        </InvoiceFiltersContext.Provider>
    );
};

export const useFilters = () => {
    const context = useContext(InvoiceFiltersContext);

    if (!context) {
        throw new Error('useFilters must be used within a FiltersProvider');
    }

    return context;
};
