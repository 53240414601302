import { Modal } from '@get-e/react-components';
import { useMediaQuery } from '@mui/material';
import { FunctionComponent } from 'react';

import AddEditHotel from '../accounts/addEditAccount/AddEditHotel';
import { Hotel } from '../accounts/api/types';

interface AddNewHotelModalProps {
    onClose: () => void;
    isOpen: boolean;
    onHotelCreated: (hotel: Hotel) => void;
}

const AddNewHotelModal: FunctionComponent<AddNewHotelModalProps> = ({ onClose, isOpen, onHotelCreated }) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Modal open={isOpen} onClose={onClose} fullScreen={!isMobile}>
            <AddEditHotel onHotelCreated={(hotel: Hotel) => onHotelCreated(hotel)} />
        </Modal>
    );
};

export default AddNewHotelModal;
