export enum StatusOption {
    UNDER_REVIEW = 'UNDER_REVIEW',
    READY_FOR_APPROVAL = 'READY_FOR_APPROVAL',
    APPROVED = 'APPROVED',
    DISPUTED_BY_GETE = 'DISPUTED_BY_GETE',
    REJECTED_BY_GETE = 'REJECTED_BY_GETE',
    DISPUTED_BY_CUSTOMER = 'DISPUTED_BY_CUSTOMER',
    REJECTED_BY_CUSTOMER = 'REJECTED_BY_CUSTOMER',
    EXPORTED = 'EXPORTED',
}

export interface InvoiceVariables {
    search?: string;
    fromDateLocal?: string;
    toDateLocal?: string;
    limit?: number;
    page?: number;
    statuses: string[];
    bookingChannelIds?: string[];
    onlyCanceledBookings?: boolean;
}

export interface UpdateStatusVariables {
    id: string;
    payload: {
        status: string;
    };
}

export interface CommentVariables {
    id: string;
    content: string;
}
