import { PaymentMethod, PaymentMethodRequest, PaymentMethodVariables } from '../pages/accounts/api/types';
import apiClient from './api';
import { PAYMENT_METHOD } from './routes';

export const listPaymentMethods = async ({ hotelId, customerId }: PaymentMethodRequest): Promise<PaymentMethod[]> => {
    const searchParams = new URLSearchParams({ ...(hotelId && { hotelId }), ...(customerId && { customerId }) }).toString();

    const { data } = await apiClient.get<PaymentMethod[]>(`${PAYMENT_METHOD}?${searchParams}`);

    return data;
};

export const createPaymentMethod = async (variables: Partial<PaymentMethodVariables>) => {
    const response = await apiClient.post<PaymentMethod>(PAYMENT_METHOD, variables);

    return response;
};

export const updatePaymentMethod = async (variables: Partial<PaymentMethodVariables>) => {
    const response = await apiClient.put<PaymentMethod>(`${PAYMENT_METHOD}/${variables.id}`, variables);

    return response;
};

export const deletePaymentMethod = async (variables: Partial<PaymentMethodVariables>) => {
    const response = await apiClient.delete<PaymentMethod>(`${PAYMENT_METHOD}/${variables.id}`);

    return response;
};
