/* eslint-disable complexity */
import { MultipleSelect, PrimaryButton, Spinner, TextField } from '@get-e/react-components';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, InputAdornment, StandardTextFieldProps, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { DateRangePicker, SingleInputDateTimeRangeField } from '@mui/x-date-pickers-pro';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { logAmplitudeEvent } from '../../amplitude/amplitude';
import { SCREEN_INVOICE } from '../../amplitude/amplKeys';
import { COLORS } from '../../constans/colors';
import { LOCAL_STORAGE_KEYS } from '../../constans/localStorageKeys';
import { CUSTOMER_INVOICE_BASE_URL, EDIT_INVOICE_BASE_URL, UPLOAD_INVOICE } from '../../constans/urlPaths';
import { useAuth } from '../../context/AuthenticatedUserContext';
import { useFilters } from '../../context/InvoiceFiltersContext';
import { Severity, useNotificationContext } from '../../context/NotificationContext';
import { DATE_FORMATS } from '../../helpers/dateFormats';
import { dateTimeRangePickerStyles, dayStyles, textFieldStyles } from '../../styles/DateTimeRangePicker';
import { useDataGridStyles } from '../accounts/styles/useDataGridStyles';
import downloadFile from './api/downloadFile';
import downloadFiles from './api/downloadFiles';
import exportInvoiceFiles from './api/exportInvoiceFiles';
import { Entity, Invoice } from './api/types';
import { useInvoices } from './api/useInvoices';
import BookingChannelSelect from './components/BookingChannelSelect';
import { useInvoicesColumns } from './hooks/useInvoicesColumns';
import { StatusOption } from './types';
import { STATUS_OPTION } from './uploadInvoice/api/types';
import { CustomFooter } from './uploadInvoice/components/CustomFooter';
import { isThisMonthRange } from './uploadInvoice/helpers';

let searchTimeoutId: ReturnType<typeof setTimeout> | number = -1;

const statusMapValues = new Map([
    [StatusOption.UNDER_REVIEW, 'Under review'],
    [StatusOption.READY_FOR_APPROVAL, 'Ready for approval'],
    [StatusOption.APPROVED, 'Approved'],
    [StatusOption.DISPUTED_BY_GETE, 'Disputed by Get-e'],
    [StatusOption.REJECTED_BY_GETE, 'Rejected by Get-e'],
    [StatusOption.DISPUTED_BY_CUSTOMER, 'Disputed by customer'],
    [StatusOption.REJECTED_BY_CUSTOMER, 'Rejected by customer'],
    [StatusOption.EXPORTED, 'Exported'],
]);

const Invoices = () => {
    const { showNotification } = useNotificationContext();
    const { user } = useAuth();
    const navigate = useNavigate();
    const dataGridClasses = useDataGridStyles();

    const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState('');

    const {
        searchPhrase,
        selectedDateRange,
        statusIds,
        bookingChannel,
        filters,
        page,
        setFiltersState,
        defaultStatusIds,
        defaultBookingChannel,
    } = useFilters();

    useEffect(() => {
        logAmplitudeEvent(SCREEN_INVOICE);
    }, []);

    const {
        data: { data: results, meta } = { data: [], meta: {} },
        isLoading,
        isRefetching,
        refetch,
    } = useInvoices({
        search: debouncedSearchPhrase,
        fromDateLocal: selectedDateRange?.includes(null)
            ? undefined
            : dayjs(selectedDateRange?.[0]).format(DATE_FORMATS['YYYY-MM-DD']),
        toDateLocal: selectedDateRange?.includes(null)
            ? undefined
            : dayjs(selectedDateRange?.[1]).format(DATE_FORMATS['YYYY-MM-DD']),
        page,
        statuses: statusIds,
        ...(filters?.cancelledBooking && { onlyCanceledBookings: filters?.cancelledBooking }),
        bookingChannelIds: bookingChannel,
    });

    const { mutate: downloadAllFilesMutation } = useMutation(downloadFiles, {
        onSuccess: async () => {
            if (!user?.isBackoffice) {
                await refetch();
            }
        },
    });

    const { mutate: downloadOneFileMutation } = useMutation(downloadFile, {
        onSuccess: async () => {
            if (!user?.isBackoffice) {
                await refetch();
            }
        },
    });

    const { mutate: exportInvoiceFilesMutation } = useMutation(exportInvoiceFiles, {
        onSuccess: () => {
            showNotification(
                'Your export has started. A CSV containing your selection will arrive in your inbox within a few minutes.',
                Severity.Info
            );
        },
        onError: (error: AxiosError<{ message: string }>) => {
            showNotification(error?.response?.data?.message || 'Something went wrong', Severity.Error);
        },
    });

    const handleSearchInputOnChange = useCallback(
        (value: string) => {
            setFiltersState({ searchPhrase: value });
            clearTimeout(searchTimeoutId);
            searchTimeoutId = setTimeout(() => {
                setDebouncedSearchPhrase(value);
            }, 600);
        },
        [setDebouncedSearchPhrase]
    );

    const handleDownloadFiles = () => {
        exportInvoiceFilesMutation({
            search: debouncedSearchPhrase,
            fromDateLocal: selectedDateRange?.includes(null)
                ? undefined
                : dayjs(selectedDateRange?.[0]).format(DATE_FORMATS['YYYY-MM-DD']),
            toDateLocal: selectedDateRange?.includes(null)
                ? undefined
                : dayjs(selectedDateRange?.[1]).format(DATE_FORMATS['YYYY-MM-DD']),
            page,
            statuses: statusIds,
            ...(filters?.cancelledBooking && { onlyCanceledBookings: filters?.cancelledBooking }),
            bookingChannelIds: bookingChannel,
        });
    };

    const handleUploadNewInovoice = () => navigate(UPLOAD_INVOICE);

    const handleRowClick: GridEventListener<'rowClick'> = (params, event) => {
        event.preventDefault();
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.INOVICE_EDITED, params.row.invoiceNumber ?? '');

        navigate(user?.isBackoffice ? `${EDIT_INVOICE_BASE_URL}${params.id}` : `${CUSTOMER_INVOICE_BASE_URL}${params.id}`, {
            state: {
                invoiceNumber: params.id,
            },
        });
    };

    const handleDownloadClick = (event: React.MouseEvent<HTMLElement>, id: number, invoiceFileName: string, files: Entity[]) => {
        event.stopPropagation();

        if (files.length > 1) {
            downloadAllFilesMutation({ id: id?.toString(), invoiceFileName });
        } else {
            downloadOneFileMutation({ id: id?.toString(), fileId: files?.[0]?.id, fileName: invoiceFileName });
        }
    };

    const columns = useInvoicesColumns({ handleDownloadClick });

    useEffect(() => {
        return () => {
            if (searchTimeoutId !== -1) {
                clearTimeout(searchTimeoutId as number);
            }
        };
    }, []);

    return (
        <Grid container alignItems="flex-start">
            <Grid container justifyContent="space-between" alignItems="flex-start">
                <Grid item>
                    <Typography
                        sx={{
                            color: COLORS.BLUE,
                            fontSize: '1.5rem',
                            fontWeight: 700,
                        }}
                    >
                        Invoices
                    </Typography>
                </Grid>
                {user?.isBackoffice && (
                    <Grid item>
                        <PrimaryButton onClick={handleUploadNewInovoice} icon={<AddIcon />}>
                            Upload Invoices
                        </PrimaryButton>
                    </Grid>
                )}
            </Grid>
            <Grid container sx={{ marginTop: '.5rem', marginBottom: '1rem' }} spacing={4}>
                <Grid item xs={12} md={4} xl={4}>
                    <TextField
                        style={{ marginBottom: 0 }}
                        type="text"
                        autoComplete="off"
                        value={searchPhrase}
                        noHelperTextSpace
                        onChange={event => {
                            handleSearchInputOnChange(event.target.value);
                        }}
                        label="Search"
                        name="search"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {isLoading || isRefetching ? <Spinner size={16} /> : <SearchIcon />}
                                </InputAdornment>
                            ),
                            onBlur: event => setFiltersState({ searchPhrase: event.target.value }),
                        }}
                        onBlur={event => setFiltersState({ searchPhrase: event.target.value })}
                    />
                </Grid>
                <Grid item xs={12} md={4} xl={4}>
                    <BookingChannelSelect
                        value={bookingChannel}
                        onSetIds={ids => {
                            setFiltersState({ bookingChannel: ids });
                        }}
                        defaultValue={defaultBookingChannel?.map(el => parseInt(el))}
                    />
                </Grid>
                <Grid item xs={12} md={4} xl={4}>
                    <DateRangePicker
                        sx={dateTimeRangePickerStyles}
                        label={isThisMonthRange(selectedDateRange) ? 'This Month' : 'Date range'}
                        onChange={newValue => setFiltersState({ selectedDateRange: newValue })}
                        slotProps={{
                            textField: {
                                ...(textFieldStyles as StandardTextFieldProps),
                            },
                            field: { clearable: true },
                            day: {
                                sx: dayStyles,
                            },
                        }}
                        slots={{ field: SingleInputDateTimeRangeField }}
                        format={DATE_FORMATS['DD MMM YYYY']}
                        value={selectedDateRange}
                    />
                </Grid>
            </Grid>
            <Grid container sx={{ display: 'flex', flexDirection: 'column' }} columnSpacing={4}>
                <Grid item xs={12} md={4}>
                    <MultipleSelect
                        label={'Invoice status'}
                        value={statusIds}
                        values={statusMapValues}
                        onSetIds={ids => {
                            setFiltersState({ statusIds: ids });
                        }}
                        defaultSelectedValue={defaultStatusIds?.map(s => STATUS_OPTION[s as keyof typeof STATUS_OPTION])}
                    />
                </Grid>
                <Grid item xs={12} display={'flex'} alignItems={'center'}>
                    <Typography fontWeight={700} marginRight={'1rem'}>
                        Filters:
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filters.cancelledBooking}
                                onChange={event =>
                                    setFiltersState({ filters: { ...filters, cancelledBooking: event.target.checked } })
                                }
                                name="cancelledBooking"
                            />
                        }
                        label="Cancelled booking (CXL)"
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <DataGrid
                    className={dataGridClasses.dataGrid}
                    disableColumnSelector
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={results ?? []}
                    columns={columns as Array<GridColDef<Invoice>>}
                    pagination
                    page={page}
                    pageSize={meta?.per_page ?? 10}
                    paginationMode="server"
                    onPageChange={newPage => setFiltersState({ page: newPage })}
                    rowCount={meta?.total ?? 0}
                    loading={isLoading || isRefetching}
                    onRowClick={handleRowClick}
                    autoHeight
                    components={{
                        Footer: () => (results?.length > 0 ? <CustomFooter handleDownload={handleDownloadFiles} /> : null),
                    }}
                    sx={{ minHeight: '600px' }}
                />
            </Grid>
        </Grid>
    );
};

export default Invoices;
