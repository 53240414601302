import { MessageDialog } from '@get-e/react-components';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, Typography, IconButton } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { COLORS } from '../constans/colors';
import { Severity, useNotificationContext } from '../context/NotificationContext';
import { PaymentMethod } from '../pages/accounts/api/types';
import { deletePaymentMethod } from '../services/paymentMethods';

const useStyles = makeStyles(() => ({
    content: {
        background: COLORS.EXTRA_LIGHT_GRAY,
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            margin: 0,
        },
    },
}));

interface Customer {
    id: number;
    name: string;
}

export interface ProcedureItem {
    customer: Customer;
    procedure: string;
}

interface Props {
    data: PaymentMethod[];
    onEdit: (id: number) => void;
    onSuccess?: () => void;
    isPageEditHotelAccount?: boolean;
}

const PaymentMethodList = ({ data, onEdit, onSuccess, isPageEditHotelAccount }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { showNotification } = useNotificationContext();
    const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);

    const [selectedId, setSelectedId] = useState('');

    const handleModal = () => {
        setIsOpenWarningModal(!isOpenWarningModal);
    };

    const { mutate: deleteMutation } = useMutation(deletePaymentMethod, {
        onSuccess: () => {
            showNotification('Payment method successfully deleted.', Severity.Info);
            handleModal();
            onSuccess?.();
        },
        onError: (error: AxiosError<{ message: string }>) => {
            showNotification(error?.response?.data?.message || t('errors.retry'), Severity.Error);
        },
    });

    const handleDelete = () => {
        deleteMutation({ id: selectedId });
    };

    return (
        <div>
            {data.map(item => (
                <Accordion key={item.id}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.content}>
                        <Typography sx={{ flexGrow: 1 }}>
                            {isPageEditHotelAccount ? item?.customer.name : item?.hotel.name}
                        </Typography>
                        <IconButton
                            onClick={event => {
                                event.stopPropagation();
                                onEdit(item.id);
                            }}
                        >
                            <EditIcon sx={{ fontSize: '1.2rem' }} />
                        </IconButton>
                        <IconButton
                            onClick={event => {
                                event.stopPropagation();
                                setSelectedId(item.id?.toString());
                                handleModal();
                            }}
                            size="small"
                        >
                            <DeleteIcon sx={{ fontSize: '1.2rem' }} />
                        </IconButton>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div dangerouslySetInnerHTML={{ __html: item.procedure }} />
                    </AccordionDetails>
                </Accordion>
            ))}
            {isOpenWarningModal && (
                <MessageDialog
                    title="Are you sure you want to delete?"
                    onClose={handleModal}
                    onConfirm={() => handleDelete()}
                    closeButtonLabel="Cancel"
                    confirmButtonLabel="Save"
                    isConfirmButtonDisplayed
                    closeButtonType="secondary"
                >
                    <Typography sx={{ padding: '1rem 0' }}>
                        You are about to delete this payment method. <br />
                        <strong>Are you sure you want to proceed?</strong>
                    </Typography>
                </MessageDialog>
            )}
        </div>
    );
};

export default PaymentMethodList;
