import { Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation } from 'react-router-dom';

import SimpleLink from '../../../components/SimpleLink';
import { LOCAL_STORAGE_KEYS } from '../../../constans/localStorageKeys';
import { BREADCRUMB_DIVIDER } from '../../../constans/placeholders';
import { CUSTOMER_INVOICE, EDIT_INVOICE, UPLOAD_INVOICE } from '../../../constans/urlPaths';
import { PageDefinition } from '../../../createRoutes';
import Page from '../../../layouts/Page';
import { SCOPES } from '../../../permissions/permissionMaps';
import PermissionsGate from '../../../permissions/PermissionsGate';
import CustomerView from '../customerView/CustomerView';
import UploadInvoice from './UploadInvoice';

export const uploadInvoice: PageDefinition = {
    path: UPLOAD_INVOICE,
    Component() {
        const { useFinancialReconcilliation } = useFlags();

        return useFinancialReconcilliation ? (
            <PermissionsGate scopes={[SCOPES.canAccessFinancial]}>
                <Page
                    breadcrumbs={{
                        name: <SimpleLink label="Upload Invoice" link={UPLOAD_INVOICE} />,
                    }}
                    isFullContent
                >
                    <UploadInvoice />
                </Page>
            </PermissionsGate>
        ) : null;
    },
};

export const editInvoice: PageDefinition = {
    path: EDIT_INVOICE,
    Component() {
        const { useFinancialReconcilliation } = useFlags();
        const { state } = useLocation();
        const id = (state as InvoiceLocationState)?.invoiceNumber;

        return useFinancialReconcilliation ? (
            <PermissionsGate scopes={[SCOPES.canAccessFinancial]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Edit invoice" link={EDIT_INVOICE} />
                                <Typography component="span">
                                    {BREADCRUMB_DIVIDER}
                                    {id && `${id}`}
                                </Typography>
                            </>
                        ),
                    }}
                    isFullContent
                >
                    <UploadInvoice />
                </Page>
            </PermissionsGate>
        ) : null;
    },
};

export const viewCustomerInvoice: PageDefinition = {
    path: CUSTOMER_INVOICE,
    Component() {
        const { useFinancialReconcilliation } = useFlags();
        const { state } = useLocation();
        const id = (state as InvoiceLocationState)?.invoiceNumber;
        const invoiceNumber = window.localStorage.getItem(LOCAL_STORAGE_KEYS.INOVICE_EDITED) ?? '';

        return useFinancialReconcilliation ? (
            <PermissionsGate scopes={[SCOPES.canAccessFinancial]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label={`Invoice ${invoiceNumber}`} link={CUSTOMER_INVOICE} />{' '}
                                <Typography component="span">
                                    {BREADCRUMB_DIVIDER}
                                    {id && `${id} ${BREADCRUMB_DIVIDER}`}
                                    View & Update invoice
                                </Typography>
                            </>
                        ),
                    }}
                    isFullContent
                >
                    <CustomerView />
                </Page>
            </PermissionsGate>
        ) : null;
    },
};

interface InvoiceLocationState {
    invoiceNumber: string;
}
