import { Grid, LinearProgress } from '@mui/material';
import { FunctionComponent } from 'react';

import largeIcon from '../../images/get-e-logo-large.png';

const Loading: FunctionComponent<{ framed: boolean }> = ({ framed }) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignContent="center"
            sx={{
                height: framed ? `calc(100vh - (${56}px + 5em))` : '100vh',
            }}
            data-testid="loading-page"
        >
            <Grid item xs={10}>
                <img
                    src={largeIcon}
                    alt="Loading Get-E portal"
                    style={{
                        maxWidth: '200px',
                        maxHeight: '47px',
                        margin: '0 auto',
                        display: 'block',
                    }}
                />
            </Grid>
            <Grid item xs={10}>
                <LinearProgress
                    style={{
                        width: '200px',
                        margin: '1em auto',
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default Loading;
