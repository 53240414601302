import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { COLORS } from '../../../../constans/colors';
import { StatusOption } from '../../types';
import { STATUS_OPTION } from '../api/types';

const useStyles = makeStyles(() => ({
    underReview: {
        color: COLORS.BLACK,
        background: COLORS.LIGHT_BLUE,
    },
    approved: {
        color: COLORS.GREEN_TEXT,
        background: COLORS.LIGHT_GREEN,
    },
    readyAndDisputed: {
        color: COLORS.RED_WARNING,
        background: COLORS.MEDIUM_ORANGE,
    },
    rejected: {
        color: COLORS.BLACK,
        background: COLORS.DARK_GRAY,
    },
    exported: {
        color: COLORS.GREEN_TEXT,
        background: COLORS.LIGHT_GREEN,
    },
}));

export const StatusComponent = ({ status }: { status: StatusOption }) => {
    const classes = useStyles();

    return (
        <Box
            sx={{ fontSize: '12px', padding: '4px', borderRadius: '4px', width: 'fit-content' }}
            className={clsx({
                [classes.underReview]: status === StatusOption.UNDER_REVIEW,
                [classes.approved]: status === StatusOption.APPROVED || status === StatusOption.EXPORTED,
                [classes.exported]: status === StatusOption.EXPORTED,
                [classes.readyAndDisputed]:
                    status === StatusOption.READY_FOR_APPROVAL ||
                    status === StatusOption.DISPUTED_BY_GETE ||
                    status === StatusOption.DISPUTED_BY_CUSTOMER,
                [classes.rejected]: status === StatusOption.REJECTED_BY_GETE || status === StatusOption.REJECTED_BY_CUSTOMER,
            })}
        >
            {STATUS_OPTION[status]}
        </Box>
    );
};
