import { FullScreenLoader, PrimaryButton } from '@get-e/react-components';
import { Pagination } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';

import { BOOKINGS, CREATE_BOOKING } from '../../../../constans/urlPaths';
import { useIsBackoffice, useIsHotelUser } from '../../../../context/AuthenticatedUserContext';
import { emptyAirline } from '../../../../images';
import { Booking, BookingStatus } from '../../api/types';
import BookingCompactCard from '../bookingCard/BookingCompactCard';
import HotelUserBookingCard from '../bookingCard/HotelUserBookingCard';

const useStyles = makeStyles({
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2rem',
    },
    emptyDiv: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2rem',
        marginBottom: '5rem',
    },
    createButton: {
        display: 'flex',
        margin: '0 auto',
    },
});

interface BookingsProps {
    res?: Booking[];
    setPage: (num: number) => void;
    countPages?: number;
    isLoadingBookings: boolean;
    isRefetchingBookings: boolean;
    updateBookingsChangeList: (id: number, status: BookingStatus, confirmationNumber: string, hotelComments: string) => void;
    removeFromUpdateList: (id: number) => void;
    showTimeToAction?: boolean;
}

const List = ({
    res,
    setPage,
    countPages,
    isLoadingBookings,
    isRefetchingBookings,
    updateBookingsChangeList,
    removeFromUpdateList,
    showTimeToAction,
}: BookingsProps) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleBookingClick = (event: React.MouseEvent<HTMLDivElement>, id: number) => {
        if (event.ctrlKey || event.metaKey) {
            window.open(`${BOOKINGS}/${id}`, '_blank');
        } else {
            navigate(`${BOOKINGS}/${id}`, {
                state: {
                    id,
                },
            });
        }
    };

    const isHotelUser = useIsHotelUser();
    const isBackoffice = useIsBackoffice();

    return (
        <>
            {(isLoadingBookings || isRefetchingBookings) && <FullScreenLoader />}
            {isHotelUser
                ? res?.map((booking: Booking) => (
                      <HotelUserBookingCard
                          key={uuid()}
                          bookingId={booking.id}
                          status={booking.status}
                          bookingNumber={booking.bookingNumber ?? ''}
                          hotelComments={booking.hotelComments}
                          checkInDate={booking.checkInDateLocal}
                          checkOutDate={booking.checkOutDateLocal}
                          guests={booking.bookingGuests}
                          customerName={booking.customerName}
                          units={booking.units}
                          pendingUpdates={booking.pendingUpdates}
                          customFields={booking.customFields}
                          timeToAction={showTimeToAction ? booking.timeToActionZulu : ''}
                          isTimeIncluded={booking.isTimeIncluded}
                          updateBookingsChangeList={updateBookingsChangeList}
                          removeFromUpdateList={removeFromUpdateList}
                          specialRequest={booking.notes}
                      />
                  ))
                : res?.map((booking: Booking) => (
                      <BookingCompactCard
                          key={uuid()}
                          bookingId={booking.id}
                          status={booking.status}
                          checkInDate={booking.checkInDateLocal}
                          checkOutDate={booking.checkOutDateLocal}
                          hotelName={booking.hotelName}
                          guests={booking.bookingGuests}
                          customerName={booking.customerName}
                          units={booking.units}
                          timeToAction={showTimeToAction ? booking.timeToActionZulu : ''}
                          onClick={event => handleBookingClick(event, booking.id)}
                      />
                  ))}
            {res?.length === 0 ? (
                <>
                    <div className={classes.emptyDiv}>
                        <img src={emptyAirline} alt="fireSpot" />
                    </div>
                    {isBackoffice && (
                        <PrimaryButton
                            onClick={() => {
                                navigate(CREATE_BOOKING);
                            }}
                            className={classes.createButton}
                        >
                            New Request
                        </PrimaryButton>
                    )}
                </>
            ) : (
                <Stack spacing={2}>
                    <Pagination
                        count={countPages}
                        color="primary"
                        className={classes.pagination}
                        onChange={(_, pageNumber) => {
                            setPage(pageNumber);
                        }}
                    />
                </Stack>
            )}
        </>
    );
};

export default List;
