/* eslint-disable @typescript-eslint/no-explicit-any */
import chameleon from '@chamaeleonidae/chmln';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { useState, useEffect, ReactNode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { SENTRY_IGNORE_ERRORS } from './constans/sentryIgnoreErrors';
import { AuthProvider } from './context/AuthenticatedUserContext';

import './index.css';
import Loading from './pages/loading/Loading';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs().isSameOrBefore(dayjs('2011-01-01')); // default milliseconds

const AppWrapper = () => {
    const [LDProvider, setLDProvider] = useState<any>(null);

    useEffect(() => {
        const initializeApp = async () => {
            Sentry.init({
                dsn: process.env.REACT_APP_SENTRY_DSN,
                ignoreErrors: SENTRY_IGNORE_ERRORS,
                beforeSend(event) {
                    if (event.message && SENTRY_IGNORE_ERRORS.includes(event.message)) {
                        return null;
                    }

                    return event;
                },
                integrations: [],
                environment: 'production',
            });

            const initialLdContext = {
                kind: 'user',
                key: 'test@get-e.com',
                name: 'get-e',
                email: 'test@get-e.com',
                anonymous: false,
                _meta: {
                    privateAttributes: ['email'],
                },
            };

            if (window.location.hostname.includes('dev')) {
                chameleon.init('SUwKvhx3SxwlHMfJWSA5y00gzqd8tBCbSdRkvDQyM88tbx-1SGoRc-Fmz1SSd4kxC9Ws30', {
                    fastUrl: 'https://fast.chameleon.io/',
                });
            }

            try {
                const Provider = await asyncWithLDProvider({
                    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '',
                    context: initialLdContext,
                    options: {
                        bootstrap: 'localStorage',
                    },
                });

                setLDProvider(() => Provider);
            } catch (error) {
                console.error('LaunchDarkly initialization error:', error);
                setLDProvider(() => ({ children }: { children: ReactNode }) => <>{children}</>);
            }
        };

        initializeApp();
    }, []);

    if (!LDProvider) {
        return <Loading framed={false} />;
    }

    return (
        <LDProvider>
            <BrowserRouter>
                <AuthProvider>
                    <App />
                </AuthProvider>
            </BrowserRouter>
        </LDProvider>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<AppWrapper />);
