export const COLORS = {
    BLACK: '#1A364C',
    BLACK_50: 'rgba(0, 0, 0, 0.5)',
    BLACK_12: '#C9C9C9',
    WHITE: '#FFFFFF',
    SLATE_GREY: '#788B9B',
    DARK_GRAY: '#BCBDCB',
    LIGHT_GRAY: '#EEF3F9',
    EXTRA_LIGHT_GRAY: '#F8F8F8',
    LIGHT_BLUE_INFO: '#03A9F4',
    BLUE_DARK: '#001154',
    BLUE: '#073690',
    LIGHT_BLUE: '#BAC3D8',
    ORANGE: '#F86909',
    ORANGE_OFFICIAL: '#FF9600',
    ORANGE_WARNING: '#E99A00',
    LIGHT_ORANGE: '#FFEBD7',
    MEDIUM_ORANGE: '#FFE3C7',
    GREEN: '#12944A',
    LIGHT_GREEN: '#EEF6F1',
    RED: '#B72323',
    RED_WARNING: '#801818',
    LIGHT_RED: '#E6C1C1',
    ORANGE_TEXT: '#BE3800',
    BLUE_TEXT: '#082E82',
    GREEN_TEXT: '#006B2E',
    YELLOW: '#FFE99C',
    TABLE_BORDER: 'rgba(224, 224, 224, 1)',
};
