import { useQuery } from 'react-query';

import { PAYMENT_METHOD } from '../../../constans/queryKeys';
import { listPaymentMethods } from '../../../services/paymentMethods';
import { PaymentMethodRequest } from './types';

export function usePaymentMethods(variables: PaymentMethodRequest) {
    return useQuery([PAYMENT_METHOD], () => listPaymentMethods(variables), {
        keepPreviousData: true,
        refetchOnWindowFocus: true,
        retry: 1,
        cacheTime: 500,
    });
}
