/* eslint-disable complexity */
import { AxiosResponse } from 'axios';

import { CommentVariables, InvoiceVariables, UpdateStatusVariables } from '../pages/invoices/types';
import { UploadInvoiceRequest } from '../pages/invoices/uploadInvoice/api/types';
import apiClient from './api';
import { getInvoiceRoute, getUpdateCommentRoute, getUpdateStatusRoute, INVOICES } from './routes';

export const createInvoice = async (variables: UploadInvoiceRequest): Promise<AxiosResponse> => {
    const { files, ...rest } = variables;
    const formData = new FormData();

    Object.entries(rest).forEach(([key, value]) => {
        formData.append(key, value as string);
    });

    files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
    });

    const response = await apiClient.post(INVOICES, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response;
};

export const listInvoices = async (variables: InvoiceVariables) => {
    const searchParams = new URLSearchParams({
        searchValue: variables?.search || '',
        fromDateLocal: variables?.fromDateLocal || '',
        toDateLocal: variables?.toDateLocal || '',
        limit: variables?.limit?.toString() || '',
        page: ((variables?.page ?? 0) + 1)?.toString() || '',
        statuses: variables?.statuses?.map(el => el).join(',') || '',
        onlyCanceledBookings: variables.onlyCanceledBookings ? '1' : '0',
        bookingChannelIds: variables?.bookingChannelIds?.map(el => el).join(',') || '',
    }).toString();

    const { data } = await apiClient.get(`${INVOICES}?${searchParams}`);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data;
};

export const updateInvoice = async (variables: UploadInvoiceRequest): Promise<AxiosResponse> => {
    const { files, id, ...rest } = variables;
    const formData = new FormData();

    formData.append('_method', 'PUT');

    Object.entries(rest).forEach(([key, value]) => {
        formData.append(key, value as string);
    });

    files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
    });

    const response = await apiClient.post(getInvoiceRoute(id ?? ''), formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response;
};

export const updateInoviceStatus = async ({ id, payload }: UpdateStatusVariables) => {
    const response = await apiClient.patch<Request>(getUpdateStatusRoute(id), payload);

    return response;
};

export const createCustomerCommment = async ({ id, content }: CommentVariables) => {
    const response = await apiClient.patch(getUpdateCommentRoute(id), { customerComment: content });

    return response;
};
